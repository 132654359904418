import { PostImage } from 'models/Medium';
import React, {useMemo} from 'react';
import ImageHolder from 'views/components/ImageHolder';

interface Props {
  image: PostImage
}
const ImageView: React.FC<Props> = ({ image }) => {
  const caption = useMemo(() => {
    if (!image || !image.caption) {
      return null;
    }
    return image.caption.map((tv, idx) => (
      <strong key={idx} className="tit_card">
        {tv.v}
      </strong>
    ));
  }, [image]);

  return (
    <div className="card_default card_image">
      <div className="wrap_thumb">
        <ImageHolder image={image} />
        <span className="frame_g" />
      </div>
      {caption}
    </div>
  );
};

export default ImageView;
