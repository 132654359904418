import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'state/modules';
import { isMobile } from 'utils/deviceUtils';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import OnlyMobileErrorPage from './OnlyMobileErrorPage';
import DefaultMobileBridgePage from './bridges/DefaultMobileBridgePage';
import ErrorPage from 'views/pages/ErrorPage';

interface CouponParam {
  encodedProfileId?: string;
}

const CouponBookPage: React.FC<RouteChildrenProps<CouponParam>> = (props) => {
  const {match} = props;
  const encodedProfileId = match?.params?.encodedProfileId;
  const params = queryString.parse(window?.location?.search);
  const {ids} = params;

  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );

  if (isEmpty(ids)) {
    return <ErrorPage />;
  }

  if (!isMobile(useragent)) {
    return <OnlyMobileErrorPage />;
  }

  let couponIds: Array<number> = [];
  if (typeof ids === 'string') {
    couponIds = ids?.split(',').map(id => parseInt(id)).filter(id => !!id);
  } else if (ids !== null && typeof ids === 'object') {
    couponIds = ids?.map(id => parseInt(id)).filter(id => !!id);
  }
  
  if (!isEmpty(couponIds)) {
    const scheme = `plusfriend/couponbook/${encodedProfileId}?ids=${couponIds.join(',')}&from=web`;
    return <DefaultMobileBridgePage scheme={scheme} />;
  }

  return <ErrorPage />;
};

export default CouponBookPage;
