import * as Sentry from '@sentry/browser';
import get from 'lodash/get';

export const initSentry = () => {
    const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
    if (!!sentryDsn) {
        const phase = `${process.env.REACT_APP_PHASE}`.toLowerCase();
        const isSandbox = phase === 'sandbox';
        Sentry.init({
            dsn: sentryDsn,
            environment: phase,
            debug: isSandbox,
            beforeSend: (event, hint) => {
                if (!event || !hint) {
                    return event;
                }

                // const url = get(event, 'request.url', '');
                // const exceptionCode = get(hint, 'originalException.code');
                const exceptionMsg = get(hint, 'originalException.message', '');

                if (isSandbox) {
                    return null;
                }

                if (exceptionMsg.indexOf('checkDomStatus') !== -1) {
                  // Cannot read property 'checkDomStatus' of undefined
                  return null;
                } else if (exceptionMsg.indexOf('window.appInterfaceUtil.triggerMessage') !== -1) {
                  // undefined is not an object (evaluating 'window.appInterfaceUtil.triggerMessage')
                  return null;
                }

                return event;
            },
            ignoreErrors: [
                'Unauthorized',
                'Forbidden',
            ]
        });
    } else {
        console.warn('SENTRY DSN 환경설정이 필요합니다.');
    }
};
