import React from 'react';
import { Post } from '../../../models/Post';
import AddFriendDoneLayer from '../AddFriendDoneLayer';
import { Profile } from '../../../models/Profile';
interface Props {
  completedLayer: string;
  post: Post;
  profile: Profile;
}

const location = window.location;
const clickBtn = () => {
  const layer = window.document.getElementById('completedLayer');
  const dimmedLayer = window.document.getElementById('dimmedLayer');
  if (!!layer) {
    layer.style.display = 'none';
  }
  if (!!dimmedLayer) {
    dimmedLayer.style.display = 'none';
  }
  // param 제거
  location.href =
    location.protocol + '//' + location.hostname + location.pathname;
};
const date = new Date();
const today =
  date.getFullYear() +
  '년 ' +
  (date.getMonth() + 1) +
  '월 ' +
  date.getDate() +
  '일';

const CompletedLayer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  completedLayer,
  post,
  profile
}) => (
  <>
    {completedLayer === 'blockfriend' && (
      <div>
        <div id="dimmedLayer" className="dimmed_layer"></div>
        <div
          className="plus_layer channel_layer"
          style={{ marginTop: '-120px' }}
        >
          <div id="completedLayer" className="layer_body">
            <strong className="tit_layer">채널 차단 완료</strong>
            <p className="txt_layer">
              {today} {post.author.name}
              <br />
              채널이 차단되었습니다.
            </p>
            <div className="app_btn">
              <button
                type="button"
                className="btn_g"
                onClick={() => clickBtn()}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
    {completedLayer === 'addfrienddone' && (
      <div>
        <AddFriendDoneLayer profile={profile} />
      </div>
    )}
  </>
);

export default CompletedLayer;
