// export { default as home } from './home';
import {combineReducers} from 'redux';
import home from './home/reducers';

const rootReducer = combineReducers({
  home
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
