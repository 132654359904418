import React from 'react';
import Gnb from 'views/components/Gnb';

const OnlyMobileErrorPage: React.FC = () => (
  <>
    <Gnb />
    <div id="kakaoContent" className="cont_bridge" role="main">
      <div className="inner_bridge">
        <span className="img_404" />
        <strong className="tit_bridge">
          모바일에서 확인 가능한 링크입니다.
        </strong>
        <p className="desc_bridge">주소가 정확한지 다시 한번 확인해주세요.</p>
      </div>
    </div>
  </>
);

export default OnlyMobileErrorPage;
