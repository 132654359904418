import React, { useEffect, useRef } from 'react';
import useScript from 'utils/useScript';
interface Props {
  show: boolean;
  x: number;
  y: number;
}

const StaticMap: React.FC<Props> = ({show, x, y}) => {
  const refStaticMap = useRef<HTMLDivElement>(null);
  const status = useScript('https://spi.maps.daum.net/imap/map_js_init/v3.js?autoload=false');

  useEffect(() => {
    if (refStaticMap && status === 'ready' && show) {
      // @ts-ignore
      const { daum } = window;
      daum.maps.load(() => {
        const mapOption = {
          center: new daum.maps.LatLng(y, x),
          level: 3,
          marker: {
            position: new daum.maps.LatLng(y, x)
          }
        };
        // if (refStaticMap?.current?.firstChild) {
        //   refStaticMap?.current?.removeChild(refStaticMap.current.firstChild);
        // }
        const map = new daum.maps.StaticMap(refStaticMap.current, mapOption);
        console.log('static map completed', x, y, map);
      });
    }
  }, [show, x, y, refStaticMap, status]);

  return (
    <div
      id="staticMap"
      className="area_map"
      ref={refStaticMap}
      style={{ height: '190px', display: show ? '' : 'none' }}
    />
  );
};

export default StaticMap;

