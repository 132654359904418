import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { homeOperations, homeSelectors } from 'state/modules/home';
import { showModal } from 'state/modules/home/actions';
import {
  ADULT_AUTH_VALID_TYPE,
  AGE_AUTH,
  androidInstallUrl,
  iosInstallUrl
} from 'state/modules/home/constants';
import ContentWrapper from 'views/components/ContentWrapper';
import PostCard from 'views/components/Post';
import CommentList from 'views/components/Post/comment/CommnetList';
import Gnb from 'views/components/Gnb';
import PostActionBar from 'views/components/Post/PostActionBar';
import { useTiara } from 'utils/tiaraHelper';
import { isBlindPost, isBlindPostContentOnly } from 'utils/postUtil';
import { RootState } from 'state/modules';
import { Post } from 'models/Post';
import openWithApp from 'utils/openWithApp';
import { getOsName, isMobile } from 'utils/deviceUtils';
import queryString from 'query-string';
import CompletedLayer from '../components/CompletedLayer';
import UrlCopyLayer from '../components/UrlCopyLayer';

interface Props {
  profileId: string;
  postId: number;
  post: Post;
  fetchPost: Function;
  fetchComments: Function;
  onShowModal: Function;
  error: any;
}

const PostPage: React.FC<Props> = ({
  profileId,
  postId,
  post,
  fetchPost,
  fetchComments,
  onShowModal,
  error
}) => {
  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );

  const params = queryString.parse(window.location.search);
  const completed = params?.completedLayer ?? 'none';
  const [copyUrlVisibility, setCopyUrlVisibility] = useState('none');

  useTiara({
    page: 'post'
  });

  useEffect(() => {
    fetchPost(profileId, postId);
  }, [fetchPost, profileId, postId]);

  useEffect(() => {
    if (!post) {
      return;
    }

    if (!window.location.search) {
      return;
    }

    const params = queryString.parse(window.location.search);
    if (params.action !== 'add_channel') {
      return;
    }

    if (isMobile(useragent)) {
      const deviceOS = getOsName(useragent);
      const postPath = `plusfriend/post/${post.author.id}/${post.id}?action=add_channel`;
      const installUrl =
        deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl;
      openWithApp(
        deviceOS,
        'kakaoplus',
        postPath,
        'com.kakao.talk',
        installUrl
      );
    } else {
      window.location.href = `/${profileId}/friend_direct?post_id=${post.id}`;
      return;
    }
  }, [fetchPost, profileId, postId, post, useragent]);

  useEffect(() => {
    if (!post) {
      return;
    }

    const profile = post.author;
    if (!profile) {
      return;
    }

    const isForAdult = profile.isAdult;
    const validType = get(
      profile,
      'authInfo.validType',
      ADULT_AUTH_VALID_TYPE.UNAUTHORIZED
    );
    if (isForAdult && validType !== ADULT_AUTH_VALID_TYPE.VERIFIED) {
      onShowModal(AGE_AUTH, { profile });
    }
  }, [post, onShowModal, profileId]);

  const layerCode = (code: string) => {
    setCopyUrlVisibility(code);
  };

  const content = useMemo(() => {
    if (!post) return null;

    const profile = post.author;
    if (!profile) {
      return;
    }

    const validType = get(
      profile,
      'authInfo.validType',
      ADULT_AUTH_VALID_TYPE.UNAUTHORIZED
    );

    const isBlind = isBlindPost(post.blindType);
    const isBlindContentOnly = isBlindPostContentOnly(post.blindType);
    const isAgeCovered =
      post.adultOnly && validType !== ADULT_AUTH_VALID_TYPE.VERIFIED;

    if (isBlind && !isBlindContentOnly) {
      return (
        <div className="view_post">
          <p className="desc_none">
            <span className="ico_plus"></span>
            운영정책에 의해 블라인드 된 소식입니다.
          </p>
        </div>
      );
    }

    return (
      <div className="view_post">
        {!isAgeCovered && (
          <PostActionBar post={post} onShowModal={onShowModal} />
        )}
        <PostCard
          type="detail"
          post={post}
          onShowModal={onShowModal}
          layerCode={layerCode}
        />
        {!isBlind && !isAgeCovered && (
          <CommentList
            post={post}
            fetchComments={fetchComments}
            onShowModal={onShowModal}
          />
        )}
      </div>
    );
  }, [fetchComments, onShowModal, post]);

  if (!post) {
    if (error?.response?.status === 404) {
      window.location.href = '/';
    }
    return (
      <>
        <Gnb />
      </>
    );
  }
  return (
    <>
      <Gnb post={post} />
      <ContentWrapper accessbility={{ content: '소식 본문영역' }}>
        {content}
        <CompletedLayer
          completedLayer={completed.toString()}
          post={post}
          profile={post.author}
        />
      </ContentWrapper>
      <UrlCopyLayer completedLayer={copyUrlVisibility} profile={post.author} />
    </>
  );
};

function mapStateToProps(state: RootState, props: any) {
  const {
    match: {
      params: { encodedProfileId: profileId, postId }
    }
  } = props;
  const {
    home: { error }
  } = state;

  return {
    profileId,
    postId: Number(postId),
    post: homeSelectors.getPost(state, props),
    error: error.error
  };
}

export default connect(mapStateToProps, dispatch =>
  bindActionCreators(
    {
      onShowModal: showModal,
      fetchPost: homeOperations.fetchPost,
      fetchComments: homeOperations.fetchComments
    },
    dispatch
  )
)(PostPage);
