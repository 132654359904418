import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'state/modules';
import { isMobile } from 'utils/deviceUtils';
import OnlyMobileErrorPage from './OnlyMobileErrorPage';
import DefaultMobileBridgePage from './bridges/DefaultMobileBridgePage';

interface CouponParam {
  encodedProfileId?: string;
  couponId?: string;
}

const CouponPage: React.FC<RouteChildrenProps<CouponParam>> = (props) => {
  const {match} = props;
  const encodedProfileId = match?.params?.encodedProfileId;
  const couponId = match?.params?.couponId;
  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );

  if (!isMobile(useragent)) {
    return <OnlyMobileErrorPage />;
  }

  const scheme = `plusfriend/coupon/${encodedProfileId}/${couponId}`;
  return <DefaultMobileBridgePage scheme={scheme} />;
};

export default CouponPage;
