import React from 'react';
import { dotFormat } from 'utils/dateFormat';
import { linkify } from 'utils/urlUtil';
import CardImageHolder from 'views/components/Post/media/CardImageHolder';

interface Props {
  coupdonCard: any; // TODO: 쿠폰카드 모델로 바꾸자
}

const CouponCardView: React.FC<Props> = ({ coupdonCard }) => {
  const {
    coupon,
    coupon: { content }
  } = coupdonCard;

  return (
    <div className="card_default card_coupon">
      <div className="inner_card">
        <div className="wrap_thumb">
          <CardImageHolder image={coupon.entryImage} />
          <span className="frame_g" />
        </div>
        <div className="info_cont">
          <div className="wrap_tit">
            <div className="inner_tit" style={{ backgroundSize: 'cover' }}>
              <strong className="tit_card">{coupon.title}</strong>
              <span className="txt_card">{`응모기간 : ${dotFormat(
                coupon.entryStartAt
              )}~${dotFormat(coupon.entryEndAt)}`}</span>
            </div>
          </div>
          <div className="desc_card" style={{ textAlign: 'initial' }}>
            {linkify(content)}
          </div>
          <em className="emph_coupon">
            쿠폰응모는 카카오톡 앱에서 할 수 있습니다.
          </em>
        </div>
      </div>
    </div>
  );
};

export default CouponCardView;
