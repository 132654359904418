import React from 'react';
import { iosInstallUrl, androidInstallUrl } from 'state/modules/home/constants';
import BaseModal from 'views/components/Modal/BaseModal';
import { useSelector } from 'react-redux';
import { RootState } from 'state/modules';
import { getOsName } from 'utils/deviceUtils';

interface Props {
  onHideModal: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  profileId: String;
  postId: String;
}
const KrewPrivateProfileModal: React.FC<Props> = ({
  onHideModal,
  profileId,
  postId
}) => {
  const useragent = useSelector<RootState, UAParser.IResult>(
    state => state.home.useragent.result
  );
  const osName = getOsName(useragent);
  const schemeHost = postId
    ? `plusfriend/post/${profileId}/${postId}`
    : `plusfriend/home/${profileId}`;
  const scheme =
    osName === 'Android'
      ? `kakaoplus://${schemeHost}`
      : `${process.env.REACT_APP_PF_CUSTOM_SCHEME}://${schemeHost}`;

  return (
    <BaseModal contentLabel="krewPrivate" onRequestClose={onHideModal}>
      {{
        renderTitle: () => <span>모바일에서 확인 해주세요.</span>,
        renderContent: () => (
          <div className="app_btn">
            <button
              type="button"
              className="btn_g"
              onClick={() => window.open(scheme)}
            >
              <span className="inner_txt">카카오톡앱 열기</span>
            </button>
            <button
              type="button"
              className="btn_g btn_g2"
              onClick={() =>
                window.open(
                  osName === 'iOS' ? iosInstallUrl : androidInstallUrl
                )
              }
            >
              <span className="inner_txt">카카오톡앱 설치하기</span>
            </button>
          </div>
        )
      }}
    </BaseModal>
  );
};

export default KrewPrivateProfileModal;
