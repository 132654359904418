import { TabType } from 'models/Profile';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  tab: TabType;
  changeTab: Function;
}

const Tab: React.FC<Props> = ({ tab, changeTab }) => {
  return (
    <div>
      <strong className="screen_out">소식 메뉴</strong>
      <ul className="list_menu">
        <li className={tab === TabType.hometab ? 'on' : ''}>
          <Link
            to="#hometab"
            onClick={changeTab('hometab')}
            className="link_menu"
          >
            홈
          </Link>
        </li>
        <li className={tab === TabType.post ? 'on' : ''}>
          <Link to="#post" onClick={changeTab('post')} className="link_menu">
            소식
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tab;
