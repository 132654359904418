import React, { useState } from 'react';
import ProfileImage from '../ProfileImage';
import timeAgoInWords from '../../../utils/timeAgoInWords';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { Post } from 'models/Post';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { TiaraTrackUtils } from '../../../utils/tiaraNewHelper';

interface Props {
  post: Post;
  isFeed: boolean;
  profilePath: string;
  postPath: string;
  layerCode: Function;
}

const PostProfileHeader: React.FC<Props> = ({
  post,
  isFeed,
  profilePath,
  postPath,
  layerCode
}) => {
  const profileImage = get(post, 'author.profileImage');
  const authorName = get(post, 'author.name');
  const [showAdditionalBtn, setShowAdditionalBtn] = useState(false);
  const currentUrl = window.location.href;
  const encodedProfileId = currentUrl.split('/')[3];
  const openSpamReportLink = () => {
    const postId = post.id;
    const spamReportUrl = `/${encodedProfileId}/spam_report/${postId}`;
    const loginRedirectUrl = window.location.origin + spamReportUrl;
    const logoutRedirectUrl = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=`;
    setShowAdditionalBtn(!showAdditionalBtn);
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/web/profiles/${encodedProfileId}/logincheck?logoutRedirectUrl=${logoutRedirectUrl}&loginRedirectUrl=${loginRedirectUrl}`,
      '_blank'
    );
  };

  return (
    <div className="post_profile">
      {post.pinned && (
        <em className="post_pin">
          <span className="ico_plus">핀 소식</span>
        </em>
      )}
      <div className="wrap_thumb">
        <a href={isFeed ? postPath : profilePath}>
          <ProfileImage image={profileImage} sizeType="small" />
        </a>
      </div>
      <div className="wrap_info">
        <strong className="txt_name">
          <a href={isFeed ? postPath : profilePath} target="_self">
            {authorName}
          </a>
        </strong>
        <span className="txt_time">
          <Link to={postPath}>
            {timeAgoInWords(new Date(post.publishedAt))}
          </Link>
        </span>
      </div>
      <div className="area_nav">
        <button
          type="button"
          className="btn_nav"
          onClick={() => {
            setShowAdditionalBtn(!showAdditionalBtn);
            TiaraTrackUtils.trackClick({
              actionName: '포스트더보기_클릭',
              page: isFeed ? 'post_archive' : 'post',
              pageMeta: { id: encodedProfileId },
              click: {
                layer1: 'post_header',
                layer2: 'post_more'
              }
            });
          }}
        >
          <span className="ico_plus ico_nav_more">더보기</span>
        </button>
        <div
          className="layer_opt"
          style={{ display: !!showAdditionalBtn ? '' : 'none' }}
        >
          <ul className="list_opt">
            <li>
              <button
                type="button"
                className="btn_opt"
                onClick={() => {
                  TiaraTrackUtils.trackClick({
                    actionName: '포스트_신고하기_클릭',
                    page: isFeed ? 'post_archive' : 'post',
                    pageMeta: { id: encodedProfileId },
                    click: {
                      layer1: 'post_header',
                      layer2: 'post_more',
                      layer3: 'post_report'
                    }
                  });
                  openSpamReportLink();
                }}
              >
                <span className="txt_opt">신고하기</span>
              </button>
            </li>
            <li>
              <CopyToClipboard
                text={`${window.location.origin}${postPath}`}
                onCopy={() => {
                  toast('URL을 복사하였습니다.');
                  setShowAdditionalBtn(!showAdditionalBtn);
                }}
              >
                <button
                  type="button"
                  className="btn_opt"
                  onClick={() => {
                    TiaraTrackUtils.trackClick({
                      actionName: '포스트_URL복사_클릭',
                      page: isFeed ? 'post_archive' : 'post',
                      pageMeta: { id: encodedProfileId },
                      click: {
                        layer1: 'post_header',
                        layer2: 'post_more',
                        layer3: 'post_url_copy'
                      }
                    });
                  }}
                >
                  URL 복사하기
                </button>
              </CopyToClipboard>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PostProfileHeader;
