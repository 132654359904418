import React, { useState, useMemo, useRef, useEffect } from 'react';
import head from 'lodash/head';
import take from 'lodash/take';
import drop from 'lodash/drop';
import size from 'lodash/size';
import isEmtpy from 'lodash/isEmpty';
import some from 'lodash/some';
import classNames from 'classnames';
import FeedCard from 'views/components/Post/feed_media/FeedCard';
import { TiaraTrackUtils } from '../../../../utils/tiaraNewHelper';

interface Props {
  cards: any;
  postPath: string;
  maxCardSize: number;
  pageSize: number;
  prefetchSize: number;
  profileId: string;
}
const CardFeedView: React.FC<Props> = ({
  cards,
  postPath,
  maxCardSize,
  pageSize,
  prefetchSize,
  profileId
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [cardListWidth, setCardListWidth] = useState(0);

  const viewCards = useMemo(
    () =>
      take(
        drop(take(cards, maxCardSize), currentPage * pageSize - 1),
        pageSize + prefetchSize * 2
      ),
    [currentPage, cards, maxCardSize, pageSize, prefetchSize]
  );

  const cardSize = Math.min(cards.length, maxCardSize);
  const hasPrev = currentPage > 0;
  const hasNext = (currentPage + 1) * 2 < cardSize;
  const isFirstPage = !hasPrev;
  const isLastPage = !hasNext;

  const prevBtnClicked = () => {
    setCurrentPage(currentPage - 1);
    TiaraTrackUtils.trackClick({
      actionName: '카드뷰_좌_이동',
      page: 'post_archive',
      pageMeta: { id: profileId },
      click: {
        layer1: 'post_body',
        layer2: 'move',
        layer3: 'left'
      }
    });
  };

  const nextBtnClicked = () => {
    setCurrentPage(currentPage + 1);
    TiaraTrackUtils.trackClick({
      actionName: '카드뷰_우_이동',
      page: 'post_archive',
      pageMeta: { id: profileId },
      click: {
        layer1: 'post_body',
        layer2: 'move',
        layer3: 'right'
      }
    });
  };

  const getLeftPosition = (viewCardLength: number) => {
    if (isFirstPage) {
      return 0;
    } else if (isLastPage && viewCardLength > 2) {
      return cardListWidth - 620;
    } else if (isLastPage && cardListWidth < 380 && viewCardLength < 3) {
      return cardListWidth - 425;
    }

    return -170;
  };

  useEffect(() => {
    if (!container.current) return;
    const containerWidth = container.current.getBoundingClientRect().width;
    setCardListWidth(containerWidth);
  }, []);

  const container = useRef<HTMLDivElement>(null);
  const hasCoupon = some(cards, card => card.type === 'couponCard');
  if (cards.length === 1) {
    return (
      <div>
        <div
          className="post_card card_single"
          ref={container}
          onClick={() => {
            TiaraTrackUtils.trackClick({
              actionName: '카드뷰_클릭',
              page: 'post_archive',
              pageMeta: { id: profileId },
              click: {
                layer1: 'post_body',
                layer2: 'cardview'
              }
            });
          }}
        >
          <FeedCard card={head(cards)} postPath={postPath} />
        </div>
        {hasCoupon && (
          <p className="desc_coupon">
            <span className="ico_plus" />
            <em className="txt_coupon">쿠폰</em>이 포함된 컨텐츠입니다.
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div className="post_card card_multi" ref={container}>
          <ul
            className="list_card"
            style={{
              width: 1300,
              marginLeft: getLeftPosition(size(viewCards))
            }}
          >
            {!isEmtpy(viewCards) &&
              viewCards.map((card, index) => {
                const moreCount =
                  cards.length - Math.min(cards.length, maxCardSize);
                const isLast =
                  index + 1 === Math.min(viewCards.length, maxCardSize) &&
                  moreCount > 0;

                return (
                  <li key={index}>
                    <FeedCard
                      card={card}
                      postPath={postPath}
                      isMultiple
                      isLast={isLast}
                      moreCount={moreCount}
                      tiaraClickHandler={() => {
                        TiaraTrackUtils.trackClick({
                          actionName: '카드뷰_클릭',
                          page: 'post_archive',
                          pageMeta: { id: profileId },
                          click: {
                            layer1: 'post_body',
                            layer2: 'cardview',
                            ordNum: index + 1
                          }
                        });
                      }}
                    />
                  </li>
                );
              })}
          </ul>
          <div className="paging_card">
            <button
              type="button"
              className={classNames('btn_paging', 'btn_prev', {
                hide: !hasPrev
              })}
              onClick={prevBtnClicked}
            >
              <span className="ico_plus">이전</span>
            </button>
            <button
              type="button"
              className={classNames('btn_paging', 'btn_next', {
                hide: !hasNext
              })}
              onClick={nextBtnClicked}
            >
              <span className="ico_plus">다음</span>
            </button>
          </div>
        </div>
        {hasCoupon && (
          <p className="desc_coupon">
            <span className="ico_plus" />
            <em className="txt_coupon">쿠폰</em>이 포함된 컨텐츠입니다.
          </p>
        )}
      </div>
    );
  }
};

export default CardFeedView;
