import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import KakaoStoryShareButton from 'views/components/KakaoStoryShareButton';
import BaseModal from 'views/components/Modal/BaseModal';

const openFbShare = (url: string) => {
  window.open(
    `http://www.facebook.com/share.php?u=${encodeURIComponent(url)}`,
    '_blank',
    'menubar=no,status=no,toolbar=no,width=600,height=500'
  );
};

interface Props {
  permalink: any;
  device: any;
  onHideModal: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}
const ShareModal: React.FC<Props> = ({ onHideModal, permalink, device }) => {
  return (
    <BaseModal contentLabel="공유하기" onRequestClose={onHideModal}>
      {{
        renderTitle: () => (
          <span>
            카카오톡 채널 소식을
            <br />
            SNS에 공유해 보세요.
          </span>
        ),
        renderContent: () => (
          <div>
            <ul className="list_sns">
              <li>
                <button
                  type="button"
                  className="btn_sns btn_facebook"
                  onClick={() => openFbShare(permalink)}
                >
                  <span className="ico_plus" />
                  페이스북
                </button>
              </li>
              <li>
                <CopyToClipboard
                  text={permalink}
                  onCopy={() => alert('URL이 복사되었습니다.')}
                >
                  <button type="button" className="btn_sns btn_url">
                    <span className="ico_plus" />
                    URL 복사
                  </button>
                </CopyToClipboard>
              </li>
            </ul>
            <div className="info_url">
              <span className="txt_info">
                URL 공유시, 아래 주소를 복사하세요.
              </span>
              <div className="url_copy">
                <span className="txt_url">{permalink}</span>
                <CopyToClipboard
                  text={permalink}
                  onCopy={() => alert('URL이 복사되었습니다.')}
                >
                  <button type="button" className="btn_copy">
                    URL 복사
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        )
      }}
    </BaseModal>
  );
};

export default ShareModal;
