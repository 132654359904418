import React from 'react';
import { useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { RootState } from 'state/modules';
import queryString from 'query-string';
import { isMobile } from 'utils/deviceUtils';
import DefaultMobileBridgePage from 'views/pages/bridges/DefaultMobileBridgePage';
import OnlyMobileErrorPage from 'views/pages/OnlyMobileErrorPage';
import ErrorPage from 'views/pages/ErrorPage';
import { isEmpty } from 'lodash';

interface Param {
  encodedProfileId?: string;
}

const BridgePage: React.FC<RouteChildrenProps<Param>> = props => {
  const { match } = props;
  const encodedProfileId = match?.params?.encodedProfileId;
  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );

  const params = queryString.parse(window.location.search);

  if (!('type' in params)) {
    return <ErrorPage />;
  }

  if (!isMobile(useragent)) {
    return <OnlyMobileErrorPage />;
  }

  if (params?.type === 'kakaoview') {
    return (
      <DefaultMobileBridgePage
        scheme={`plusfriend/home/${encodedProfileId}?default_tab=creatorCenter`}
      />
    );
  } else if (params?.type === 'manager') {
    return (
      <DefaultMobileBridgePage
        scheme={`plusfriend/manager/${encodedProfileId}`}
      />
    );
  } else if (!isEmpty(params?.type)) {
    return (
      <DefaultMobileBridgePage
        scheme={`plusfriend/home/${encodedProfileId}?default_tab=${params?.type}`}
      />
    );
  }

  return <ErrorPage />;
};

export default BridgePage;
