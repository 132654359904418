import { Content } from 'models/Common';
import React from 'react';
import nl2br from './nl2br';

export const URL_REGEX = /((http|https|ftp):\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

export function hasUrlString(str: string) {
  if (!str) return false;

  const result = str.match(URL_REGEX);
  return !!(result && result.length > 0);
}

export function getUrlString(str: string) {
  if (!str) return false;
  const result = str.match(URL_REGEX);
  return !result ? [] : result;
}

export function linkify(contents: string | Content[]) {
  if (typeof contents === 'string') {
    return <span>{nl2br(contents)}</span>;
  }

  return contents.map((e, idx) => {
    const { t, v } = e;
    if (t === 'link') {
      const url =
        v.startsWith('http://') || v.startsWith('https://') ? v : `http://${v}`;
      return (
        <a
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          className="link_url"
          key={idx}
        >
          {nl2br(v)}
        </a>
      );
    }
    return <span key={idx}>{nl2br(v)}</span>;
  });
}

export function isBizpluginUrl(url: string): boolean {
  return /:\/\/bizplugin/.test(url);
}
