import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  SHARE,
  PROFILE_IMAGE,
  COMMENT,
  LIKE,
  AGE_AUTH,
  ADD_FRIEND,
  BLOCK_FRIEND,
  KREW_PRIVATE_PROFILE
} from 'state/modules/home/constants';
import { showModal, hideModal } from 'state/modules/home/actions';
import ShareModal from 'views/components/Modal/ShareModal';
import CommentModal from 'views/components/Modal/CommentModal';
import ProfileImageModal from 'views/components/Modal/ProfileImageModal';
import LikeModal from 'views/components/Modal/LikeModal';
import AgeAuthAlertModal from 'views/components/Modal/AgeAuthAlertModal';
import AddFriendModal from 'views/components/Modal/AddFriendModal';
import BlockFriendModal from 'views/components/Modal/BlockFriendModal';
import KrewPrivateProfileModal from 'views/components/Modal/KrewPrivateProfileModal';
import { RootState } from 'state/modules';

const MODAL_COMPONENTS: any = {
  [SHARE]: ShareModal,
  [COMMENT]: CommentModal,
  [LIKE]: LikeModal,
  [PROFILE_IMAGE]: ProfileImageModal,
  [AGE_AUTH]: AgeAuthAlertModal,
  [ADD_FRIEND]: AddFriendModal,
  [BLOCK_FRIEND]: BlockFriendModal,
  [KREW_PRIVATE_PROFILE]: KrewPrivateProfileModal
};

interface Props {
  modalType: string;
  modalProps: any;
  onShowModal: Function;
  onHideModal: Function;
}
const ModalRoot: React.FC<Props> = ({
  modalType,
  modalProps,
  onShowModal,
  onHideModal
}) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return (
    <SpecificModal
      onShowModal={onShowModal}
      onHideModal={onHideModal}
      {...modalProps}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    home: {
      modal: { modalType, modalProps }
    }
  } = state;
  return {
    modalType: modalType,
    modalProps: modalProps
  };
};

export default connect(mapStateToProps, dispatch =>
  bindActionCreators(
    {
      onShowModal: showModal,
      onHideModal: hideModal
    },
    dispatch
  )
)(ModalRoot);
