import { Profile } from 'models/Profile';
import React from 'react';

interface Props {
  profile: Profile;
}
const BlockFriendLayer: React.FC<Props> = ({ profile }) => {
  const date = new Date();
  const today =
    date.getFullYear() +
    '년 ' +
    (date.getMonth() + 1) +
    '월 ' +
    date.getDate() +
    '일';

  return (
    <div>
      <div id="dimmedLayer" className="dimmed_layer"></div>
      <div className="plus_layer channel_layer" style={{ marginTop: '-120px' }}>
        <div id="completedLayer" className="layer_body">
          <strong className="tit_layer">채널 차단 완료</strong>
          <p className="txt_layer">
            {today} {profile?.name}
            <br />
            채널이 차단되었습니다.
          </p>
          <div className="app_btn">
            <button
              type="button"
              className="btn_g"
              onClick={() => {
                const layer = window.document.getElementById('completedLayer');
                const dimmedLayer = window.document.getElementById(
                  'dimmedLayer'
                );
                if (!!layer) {
                  layer.style.display = 'none';
                }
                if (!!dimmedLayer) {
                  dimmedLayer.style.display = 'none';
                }
                // param 제거
                window.location.href =
                  window.location.protocol +
                  '//' +
                  window.location.hostname +
                  window.location.pathname;
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockFriendLayer;
