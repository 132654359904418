import React from 'react';
import {
  LeverageTabModule,
  LeverageTabSimplePost
} from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import HomeTabHeader from './HomeTabHeader';
import { durationFormat } from 'utils/timeUtil';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import getImageUrl from 'utils/getImageUrl';
import imgNoImg from 'images/thumb_noimg_335x335.png';

interface Props {
  profile: Profile;
  module: LeverageTabModule;
  changeTab: Function;
}
const ModuleSimplePost: React.FC<Props> = ({ profile, module, changeTab }) => {
  return (
    <div className="wrap_home wrap_home_news">
      <HomeTabHeader header={module?.header} />
      <ul className="list_news">
        {sortBy(module?.contents, 'sort').map((post: LeverageTabSimplePost) => {
          const isVideo = post?.media?.type === 'video';
          const isCard = post?.media?.type === 'card';
          const isLink = post?.media?.type === 'link';
          let image = post?.media?.image;
          let isLive = false;
          if (isVideo) {
            image = post?.media?.video?.thumbnail;
            isLive = post?.media?.video?.isLive;
          } else if (isLink) {
            image = post?.media?.link?.image;
          }
          return (
            <li key={post.id}>
              <Link
                to={`/${profile.profileId}/${post.id}`}
                className="link_item"
              >
                <span
                  className="thume_item"
                  style={{
                    backgroundImage: `url(${image?.imageUrl ? getImageUrl(image?.imageUrl) : imgNoImg})`
                  }}
                >
                  {isVideo && !isLive && (
                    <span className="txt_movie">
                      {durationFormat(post?.media?.video?.duration)}
                    </span>
                  )}
                  {isVideo && isLive && (
                    <span className="ico_plus ico_live">LIVE</span>
                  )}
                  {isCard && (
                    <span className="txt_count">{post?.media?.count}</span>
                  )}
                </span>
                <span className="info_item">
                  <span className="txt_g">{post?.title?.text}</span>
                  {isLink && (
                    <em className="emph_g">{post?.media?.link?.host}</em>
                  )}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
      {!!module?.contents && module.contents.length >= 3 && (
        <a href="#none" className="btn_more" onClick={changeTab('post')}>
          소식 전체보기
        </a>
      )}
    </div>
  );
};

export default ModuleSimplePost;
