import { Profile } from 'models/Profile';
import React from 'react';

interface Props {
  profile: Profile;
}
const EmptyPost: React.FC<Props> = ({ profile }) => {
  const { spamLevel } = profile;
  const desc =
    spamLevel === 5
      ? '운영정책에 의해 제재된 프로필로 모든 소식이 블라인드되었습니다.'
      : spamLevel === 6
      ? '권리침해신고에 의해 임시조치된 프로필로 모든 소식이 블라인드되었습니다.'
      : '아직 작성된 소식이 없습니다.';

  return (
    <div>
      <p className="desc_none">{desc}</p>
    </div>
  );
};

export default EmptyPost;
