import React from 'react';
import { Link } from 'react-router-dom';
import CardImageHolder from 'views/components/Post/media/CardImageHolder';

interface Props {
  card: any;
  postPath: string;
  isMultiple?: boolean;
  isLast?: boolean;
  moreCount?: number;
  tiaraClickHandler?: Function;
}
const FeedCard: React.FC<Props> = ({ card, postPath, isMultiple, isLast, moreCount, tiaraClickHandler }) => {
  return (
    <div>
      <Link to={postPath} className="card_default"
        onClick={() => {
        if (tiaraClickHandler) tiaraClickHandler();
      }}>
        <div className="inner_card">
          <div className="wrap_thumb" style={{ borderRadius: '3px 3px 0 0' }}>
            {isMultiple ? (
              <CardImageHolder
                image={
                  card.type === 'couponCard'
                    ? card.coupon.entryImage
                    : card.medium
                }
                cardWidth={190}
                cardHeight={190}
                viewType={card.tviewType}
                noGifBlur={true}
                noFrame={true}
              />
            ) : (
              <CardImageHolder
                image={
                  card.type === 'couponCard'
                    ? card.coupon.entryImage
                    : card.medium
                }
                noGifBlur={true}
                viewType={card.viewType}
              />
            )}
            {isMultiple && isLast && (
              <span className="num_count">
                {`+${moreCount}`}
                <span className="screen_out">개 더보기</span>
              </span>
            )}
          </div>
          <div className="info_cont">
            <strong className="tit_card">
              {isMultiple && isLast
                ? '컨텐츠 더보기'
                : card.type === 'couponCard'
                ? card.coupon.title
                : card.title}
            </strong>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default FeedCard;
