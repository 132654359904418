import React from 'react';
import Gnb from 'views/components/Gnb';
import ContentWrapper from 'views/components/ContentWrapper';
import ErrorContent from 'views/components/ErrorContent';

const ErrorPage: React.FC = () => (
  <>
    <Gnb />
    <ContentWrapper accessbility={{ content: '에러 본문영역' }} fillPage={true}>
      <ErrorContent
        title="페이지를 찾을 수 없습니다."
        description={
          '비공개 또는 삭제된 프로필일 수 있습니다.\n또는 주소가 정확한지 다시 한번 확인해주세요.'
        }
      />
    </ContentWrapper>
  </>
);

export default ErrorPage;
