import React, { useMemo, useCallback } from 'react';
import { LeverageTabModule, LeverageTabChat } from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import HomeTabHeader from './HomeTabHeader';
import { isEmpty } from 'lodash';
import { convertToTime } from 'utils/timeUtil';
import moment from 'moment-timezone';

const shortWeekdays = ['월', '화', '수', '목', '금', '토', '일'];

interface Props {
  profile: Profile;
  module: LeverageTabModule;
}
const ModuleChat: React.FC<Props> = ({ profile, module }) => {
  const chat: LeverageTabChat = module?.contents[0];

  const isConsultableNow = (
    startTime: string,
    endTime: string,
    kstHHmm: string
  ) => {
    const timeVal = Number(kstHHmm);
    const startTimeVal = Number(startTime + '00');
    let endTimeVal = Number(endTime + '00');
    if (endTimeVal < startTimeVal || endTimeVal === 0) {
      endTimeVal = 240000;
    }
    // console.log(`on(${timeVal >= startTimeVal && timeVal <= endTimeVal}), current(${timeVal}), start(${startTimeVal}), end(${endTimeVal})`);
    return timeVal >= startTimeVal && timeVal <= endTimeVal;
  };

  const timeStatement = (startTime: string, endTime: string) => {
    return `${convertToTime(startTime)}~${convertToTime(
      endTime === '0000' ? '2400' : endTime
    )}`;
  };

  const chatSingleTime = useCallback(() => {
    const { weekFlags, startAt, endAt } = chat;
    let weeks;
    if (weekFlags === '1111111') {
      weeks = '매일';
    } else {
      const splitWeeksFlags = weekFlags.split('');
      weeks = splitWeeksFlags
        .map((wk, idx) => {
          if (wk === '1') {
            return shortWeekdays[idx];
          }
          return '';
        })
        .filter(t => !isEmpty(t))
        .join(',');
    }
    const time = timeStatement(startAt, endAt);
    return (
      <ul className="list_time">
        <li>
          <em>{weeks}</em>
          {time}
        </li>
      </ul>
    );
  }, [chat]);

  const chatWeekTime = useCallback(() => {
    const { timeGroups } = chat;
    return (
      <ul className="list_time">
        {timeGroups.map(timeGroup => {
          const { daysAlias, days, startAt, endAt } = timeGroup;
          let dayHeader;
          if (daysAlias && daysAlias === 'everyday') {
            dayHeader = '매일';
          } else {
            dayHeader = days
              .split(',')
              .map(day => {
                const dayNum = Number(day);
                return dayNum >= 0 && dayNum <= 6 ? shortWeekdays[dayNum] : '';
              })
              .filter(day => !isEmpty(day))
              .join(',');
          }

          let dayContent;
          if (startAt && endAt && startAt.length === 4 && endAt.length === 4) {
            dayContent = timeStatement(startAt, endAt);
          }
          return (
            <li>
              <em>{dayHeader}</em>
              {dayContent}
            </li>
          );
        })}
      </ul>
    );
  }, [chat]);

  const chatTime = useMemo(() => {
    const { weekFlags, timeGroups } = chat;
    const hasSingleTime = weekFlags && weekFlags !== '0000000';
    const hasWeekTime = !hasSingleTime && timeGroups;

    return (
      (hasSingleTime || hasWeekTime) && (
        <div className="info_item">
          <span className="ico_plus ico_time" />
          {hasSingleTime && chatSingleTime()}
          {hasWeekTime && chatWeekTime()}
        </div>
      )
    );
  }, [chat, chatSingleTime, chatWeekTime]);

  const chatOn = useMemo(() => {
    const { weekFlags, startAt, endAt, timeGroups } = chat;
    const hasSingleTime = weekFlags && weekFlags !== '0000000';
    const hasWeekTime = !hasSingleTime && timeGroups;

    const kstTime = moment()
      .tz('Asia/Seoul')
      .format('e,HHmmss')
      .split(',');
    const kstDayIdx = kstTime[0] === '0' ? 6 : Number(kstTime[0]) - 1; //0 ~ 6 (0 is monday, 6 is sunday)
    const kstHHmm = kstTime[1]; //ex: 090000
    if (
      hasSingleTime &&
      weekFlags.length === 7 &&
      weekFlags[kstDayIdx] === '1'
    ) {
      return isConsultableNow(startAt, endAt, kstHHmm);
    }
    if (hasWeekTime) {
      return timeGroups.some(timeGroup => {
        if (timeGroup.days.indexOf(String(kstDayIdx)) >= 0) {
          return isConsultableNow(timeGroup.startAt, timeGroup.endAt, kstHHmm);
        }
        return false;
      });
    }
    return false;
  }, [chat]);

  return (
    <div className="wrap_home wrap_home_chatting">
      <HomeTabHeader header={module?.header} />
      <ul className="list_chatting">
        {chat?.isChatBot && (
          <li>
            <a
              href={`/${profile.profileId}/chat`}
              className="link_item"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="ico_plus ico_chatbot" />
              <div className="info_item">
                <span className="txt_info">
                  챗봇 채팅
                  <span className="ico_plus ico_on">ON</span>
                </span>
                <span className="desc_info">{chat?.botStartMessage}</span>
              </div>
              <span className="ico_plus ico_more" />
            </a>
          </li>
        )}
        {chat?.consultFlag && (
          <li>
            <a
              href={`/${profile.profileId}/chat`}
              className="link_item"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="ico_plus ico_customer" />
              <div className="info_item">
                <span className="txt_info">
                  상담원 채팅
                  {chatOn && <span className="ico_plus ico_on">ON</span>}
                </span>
                <span className="desc_info">
                  간편하게 채팅으로 문의해보세요.
                </span>
              </div>
              {chatTime}
              <span className="ico_plus ico_more" />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ModuleChat;
