export function dotFormat(anyDate: number | Date | string) {
  const date = new Date(anyDate);
  const year = date.getFullYear();
  const month =
    date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return `${year}.${month}.${day}`;
}

export function wordFormat(anyDate: number | Date | string | undefined) {
  if (typeof anyDate !== 'undefined') {
    const date = new Date(anyDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}년 ${month}월 ${day}일`;
  }
}
