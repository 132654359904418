import { Profile } from 'models/Profile';
import React from 'react';
import { wordFormat } from '../../../utils/dateFormat';

interface Props {
  profile: Profile;
}
const AddFriendDoneLayer: React.FC<Props> = ({ profile }) => {
  return (
    <div>
      <div id="dimmedLayer" className="dimmed_layer"></div>
      <div className="plus_layer channel_layer" style={{ marginTop: '-120px' }}>
        <div id="completedLayer" className="layer_body">
          <strong className="tit_layer">채널 추가 완료</strong>
          <p className="txt_layer">
            {wordFormat(profile.friendAt)} {profile?.name}
            <br />
            채널을 추가하였습니다.
          </p>
          <div className="app_btn">
            <button
              type="button"
              className="btn_g"
              onClick={() => {
                const layer = window.document.getElementById('completedLayer');
                const dimmedLayer = window.document.getElementById(
                  'dimmedLayer'
                );
                if (!!layer) {
                  layer.style.display = 'none';
                }
                if (!!dimmedLayer) {
                  dimmedLayer.style.display = 'none';
                }

                const currentUrl = window.location.href;
                const encodedProfileId = currentUrl.split(/\/|\?/)[3];
                const postId = currentUrl.split(/\/|\?/)[4] || '';
                if (postId === '' || !parseInt(postId)) {
                  window.location.href =
                    window.location.protocol +
                    '//' +
                    window.location.hostname +
                    '/' +
                    encodedProfileId;
                } else {
                  window.location.href =
                    window.location.protocol +
                    '//' +
                    window.location.hostname +
                    '/' +
                    encodedProfileId +
                    '/' +
                    postId;
                }
              }}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFriendDoneLayer;
