import { combineReducers } from 'redux';
import produce, { enableES5 } from 'immer';
import get from 'lodash/get';
import merge from 'lodash/merge';
import types from 'state/modules/home/types';
import { direction } from 'state/modules/home/constants';
import { getUseragent } from 'utils/useragentUtil';

enableES5();

const entitiesReducer = produce((draft = {}, action) => {
  switch (action.type) {
    case types.FETCH_PROFILE_SUCCESS:
    case types.FETCH_POST_SUCCESS:
      merge(draft, action.entities);
      break;
    case types.FETCH_POSTS_SUCCESS:
      draft.profiles[action.profileId].posts.hasNext = action.hasNext;
      draft.profiles[action.profileId].posts.items.push(...action.result);
      Object.keys(action.entities)?.forEach(key => {
        // "posts", "links", "videos", "images", "cards", "coupons", "couponCards", ...
        draft[key] = Object.assign(draft[key] || {}, action.entities[key]);
      });
      break;
    case types.FETCH_COMMNETS_SUCCESS:
      const comments = draft.posts[action.postId].comments;
      const previousDirection = get(comments, 'direction', direction.backward);
      const newDirection = action.direction;
      if (previousDirection !== newDirection) {
        comments.direction = newDirection;
        comments.items = [];
        draft.comments = {};
      }
      comments.items =
        comments.direction === direction.forward
          ? comments.items.concat(action.result)
          : action.result.concat(comments.items);
      comments.hasNext = action.hasNext;
      draft.comments = Object.assign(draft.comments, action.entities.comments);
      break;
    case types.FETCH_REALTIME_SUCCESS:
      draft.realtime = action.result;
      break;
    default:
      return draft;
  }

  return draft;
});

const initModalState = {
  modalType: '',
  modalProps: {}
};

const modalReducer = (state = initModalState, action: any) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case types.HIDE_MODAL:
      return initModalState;
    default:
      return state;
  }
};
const initUAState = {
  result: getUseragent()
};
const useragentReducer = (state = initUAState) => {
  return { ...state };
};

const errorReducer = (state = { error: undefined }, { error }: any) => {
  if (error) {
    return { error };
  }

  return state;
};

const home = combineReducers({
  entities: entitiesReducer,
  modal: modalReducer,
  useragent: useragentReducer,
  error: errorReducer
});

export default home;
