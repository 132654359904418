import React from 'react';
import Modal from 'react-modal';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const defaultStyle: ReactModal.Styles = {
  content: {
    marginTop: '-98px',
    backgroundColor: 'rgba(255,255,255,1)'
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9998,
    backgroundColor: 'rgba(0,0,0,0.75)'
  }
};

interface Props {
  children: any;
  contentLabel: string;
  style?: ReactModal.Styles;
  onRequestClose?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}
const BaseModal: React.FC<Props> = ({
  children: { renderTitle, renderContent, isButtonInside },
  onRequestClose,
  ...rest
}) => (
  <Modal
    isOpen={true}
    className="plus_layer channel_layer"
    style={defaultStyle}
    onRequestClose={onRequestClose}
    {...rest}
  >
    <div className="layer_body">
      {renderTitle && <strong className="tit_layer">{renderTitle()}</strong>}
      {renderContent()}
      {!isButtonInside && (
        <button type="button" className="btn_close" onClick={onRequestClose}>
          <span className="ico_plus ico_close">레이어 닫기</span>
        </button>
      )}
    </div>
  </Modal>
);

export default BaseModal;
