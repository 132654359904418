import { useEffect } from 'react';
import merge from 'lodash/merge';

const ifTiaraExists = (fn: Function) => (...args: any[]) =>
  window.TiaraTracker && fn(...args);

const getDefaultOpions = () => {
  const tiaraPhase =
    process.env.REACT_APP_PHASE === 'sandbox' ? 'dev' : 'production';

  return {
    svcDomain: 'pf.kakao.com',
    deployment: tiaraPhase
  };
};

export const initTiara = ifTiaraExists((options: any) => {
  const opts = merge(getDefaultOpions(), options);
  window.TiaraTracker?.getInstance()
    .init(opts)
    .setSection('webview');
});

export const useTiara = ifTiaraExists(({ page }: any) => {
  useEffect(() => {
    setTiaraPage(page);
    tiaraTrackPage(page + '_view');
  }, [page]);

  useEffect(() => {
    window.addEventListener('mousedown', onTiaraTrackClick);

    return () => window.removeEventListener('mousedown', onTiaraTrackClick);
  });

  return;
});

const setTiaraPage = (page: any) =>
  window.TiaraTracker?.getInstance().setPage(page);

const tiaraTrackPage = (trackPage: any) => {
  window.TiaraTracker?.getInstance()
    .trackPage(trackPage)
    .track();
};

const onTiaraTrackClick = (e: any) => {
  const clickInfo = window.TiaraTracker.getClickInfo(e);
  if (!clickInfo) {
    return;
  }
  const { click, eventMeta } = clickInfo;
  if (click.click_url) {
    tiaraTrackClick(click, eventMeta);
  }
};

const tiaraTrackClick = (
  click = {},
  eventMeta = {},
  customProps = {},
  actionKind = ''
) => {
  window.TiaraTracker?.getInstance()
    .trackEvent('click')
    .actionKind(actionKind)
    .eventMeta(eventMeta)
    .click(click)
    .customProps(customProps)
    .track();
};
