import React, { useState } from 'react';
import classNames from 'classnames';
import { LeverageTabModule, LeverageTabInfo } from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import HomeTabHeader from './HomeTabHeader';
import { sortBy } from 'lodash';
import HomeTabInfoItem from './HomeTabInfoItem';

interface Props {
  profile: Profile;
  module: LeverageTabModule;
}
const ModuleBusinessInfo: React.FC<Props> = ({ profile, module }) => {
  const infos: Array<LeverageTabInfo> = sortBy(module?.contents, 'sort');
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="wrap_home wrap_home_info2">
      <HomeTabHeader header={module?.header} outerNodeMode={true}>
        <button
          type="button"
          className={classNames('btn_arrow', { active: showInfo })}
          onClick={() => setShowInfo(!showInfo)}
        >
          <span className="ico_plus ico_arrow">
            사업자 등록 정보 {showInfo ? '닫기' : '보기'}{' '}
          </span>
        </button>
      </HomeTabHeader>
      {!!showInfo && (
        <dl className="list_home">
          {infos?.map((info, idx) => (
            <HomeTabInfoItem key={idx} info={info} />
          ))}
        </dl>
      )}
    </div>
  );
};

export default ModuleBusinessInfo;
