import React from 'react';
import { Profile } from '../../models/Profile';

interface Props {
  profile: Profile;
  viewBottomSheet: Function;
}

const BottomSheet: React.FC<Props> = ({ viewBottomSheet, profile }) => {
  if (profile.verificationType === 'NONE') {
    return <></>;
  }
  const title = profile.verificationGuide.title;
  const msg = profile.verificationGuide.msg;

  const openSpamReportLink = () => {
    const currentUrl = window.location.href;
    const encodedProfileId = currentUrl.split('/')[3];
    const spamReportUrl = `/${encodedProfileId}/spam_report`;
    const loginRedirectUrl = window.location.origin + spamReportUrl;
    const logoutRedirectUrl = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=`;
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/web/profiles/${encodedProfileId}/logincheck?logoutRedirectUrl=${logoutRedirectUrl}&loginRedirectUrl=${loginRedirectUrl}`,
      '_blank'
    );
  };
  return (
    <>
      <div className="dimmed_layer" onClick={e => viewBottomSheet(e)}></div>
      <div className="layer_btm" role="dialog">
        <div className="layer_head">
          <strong className="tit_layer">{title}</strong>
        </div>
        <div className="layer_body">
          <p className="desc_layer">{msg}</p>
          <p className="info_layer">
            사기 및 사칭이 의심되면
            <a
              href="#none"
              onClick={() => openSpamReportLink()}
              className="link_layer"
            >
              신고
            </a>
            해 주세요.
          </p>
          <button
            type="button"
            className="btn_close"
            onClick={e => viewBottomSheet(e)}
          >
            <span className="ico_plus ico_close">닫기</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default BottomSheet;
