import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/modules';
import { androidInstallUrl, iosInstallUrl } from 'state/modules/home/constants';
import Slider from 'react-slick';
import { LeverageTabModule } from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import moment from 'moment';
import HomeTabHeader from './HomeTabHeader';
import openWithApp from 'utils/openWithApp';
interface Props {
  profile: Profile;
  module: LeverageTabModule;
}
const ModuleCoupon: React.FC<Props> = ({ profile, module }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const refSlideCoupon = useRef<Slider>(null);
  const useragent = useSelector((state: RootState) => state.home.useragent);
  const items: Array<any> = new Array(...module?.contents[0]?.items);
  const couponBook = module?.contents[0]?.couponBook;
  if (couponBook) {
    items.push(couponBook);
  }
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => setSlideIndex(index)
  };

  const handleClickCoupon = (e: any, id: number) => {
    const deviceOS = useragent?.result?.os?.name || '';
    const isPC = deviceOS !== 'Android' && deviceOS !== 'iOS';
    const idStr = id > 0 ? id.toString() : '';
    if (isPC) {
      alert('PC에서는 사용하실 수 없습니다.\n모바일에서 확인해 주세요.');
    } else {
      openWithApp(
        deviceOS,
        deviceOS === 'iOS'
          ? process.env.REACT_APP_PF_CUSTOM_SCHEME || 'kakaoplus'
          : 'kakaoplus',
        `plusfriend/coupon/${profile?.id}/${idStr}?from=webhome`,
        process.env.REACT_APP_ANDROID_PACKAGE_NAME || 'com.kakao.talk',
        deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl
      );
    }
  };

  const handleClickCouponBook = (e: any) => {
    const deviceOS = useragent?.result?.os?.name || '';
    const isPC = deviceOS !== 'Android' && deviceOS !== 'iOS';
    if (isPC) {
      alert('PC에서는 사용하실 수 없습니다.\n모바일에서 확인해 주세요.');
    } else {
      openWithApp(
        deviceOS,
        deviceOS === 'iOS'
          ? process.env.REACT_APP_PF_CUSTOM_SCHEME || 'kakaoplus'
          : 'kakaoplus',
        `plusfriend/couponbook/${profile?.id}?from=webhome`,
        process.env.REACT_APP_ANDROID_PACKAGE_NAME || 'com.kakao.talk',
        deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl
      );
    }
  };

  const renderCoupon = (item: any, idx: number) => {
    const startAt = moment(
      !item?.isDownloaded ? item?.entryStartAt : item?.usedStartAt
    );
    const endAt = moment(
      !item?.isDownloaded ? item?.entryEndAt : item?.usedEndAt
    );
    return (
      <li key={idx}>
        <button
          type="button"
          className="item_slide btn_slide"
          onClick={e => handleClickCoupon(e, item?.id)}
        >
          <span className="thumb_item">
            <img src={item?.image?.imageUrl} className="img_thumb" alt="" />
          </span>
          <div className="cont_item">
            <strong className="tit_g">{item?.title?.text}</strong>
            <span className="txt_g">
              {startAt.format('YYYY.MM.DD')} ~ {endAt.format('YYYY.MM.DD')}
            </span>
          </div>
          <span className="icon_item">
            {item?.isDownloaded && <span className="txt_btn">사용하기</span>}
            {!item?.isDownloaded && (
              <span className="ico_plus ico_download">쿠폰 받기</span>
            )}
          </span>
        </button>
      </li>
    );
  };

  const renderCouponBook = (item: any, idx: number) => {
    return (
      <li key={idx}>
        <button
          type="button"
          className="item_slide btn_slide"
          style={{ height: 78 }}
          onClick={e => handleClickCouponBook(e)}
        >
          <div className="cont_item">
            <strong className="tit_g">
              응모 가능한 쿠폰이 {item?.count}개 있습니다.
            </strong>
            <span className="txt_g">지금 바로 쿠폰 혜택을 받아보세요!</span>
          </div>
          <span className="icon_item">
            <span className="ico_plus ico_arrow" />
          </span>
        </button>
      </li>
    );
  };

  return (
    <div className="wrap_home wrap_home_coupon">
      <HomeTabHeader header={module?.header} />
      <div className="wrap_slide">
        <div className="area_slide">
          <ul className="list_slide">
            <Slider {...settings} ref={refSlideCoupon}>
              {items.map((item, idx) => {
                if (item.id) {
                  return renderCoupon(item, idx);
                }
                return renderCouponBook(item, idx);
              })}
            </Slider>
          </ul>
        </div>
        {items.length > 1 && (
          <div className="area_paging">
            <a
              href="#none"
              className="btn_page btn_prev"
              onClick={() => refSlideCoupon?.current?.slickPrev()}
            >
              <span className="ico_plus">이전</span>
            </a>
            <div className="inner_paging">
              {items.map((item, idx) => {
                if (idx === slideIndex) {
                  return (
                    <em key={idx} className="ico_dot">
                      <span className="screen_out">현재페이지 ${idx + 1}</span>
                    </em>
                  );
                }
                return (
                  <span key={idx} className="ico_dot">
                    <span className="screen_out">{idx + 1}</span>
                  </span>
                );
              })}
            </div>
            <a
              href="#none"
              className="btn_page btn_next"
              onClick={() => refSlideCoupon?.current?.slickNext()}
            >
              <span className="ico_plus">다음</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModuleCoupon;
