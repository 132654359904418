import { isEmpty } from 'lodash';
import React from 'react';

export default function nl2br(text: string = '', removeEmpty = false) {
  if (typeof text === 'undefined' || !text) {
    return '';
  }

  let splitByNewLine = text.split('\n');
  if (removeEmpty) {
    splitByNewLine = splitByNewLine.filter(t => !isEmpty(t));
  }
  const lengthOfSplitByNewLine = splitByNewLine.length;
  return splitByNewLine.map((e, idx) => (<span key={idx}>{e}{ idx !== (lengthOfSplitByNewLine - 1) ? <br /> : null}</span>));
}

