import React from 'react';
import get from 'lodash/get';
import openWithApp from 'utils/openWithApp';
import { androidInstallUrl, iosInstallUrl } from 'state/modules/home/constants';
import { Profile } from 'models/Profile';
import { Post } from 'models/Post';
import { TiaraTrackUtils } from 'utils/tiaraNewHelper';

interface Props {
  profile?: Profile;
  post?: Post;
  postArchive?: boolean;
  useragent: any;
}
const OpenAppButton: React.FC<Props> = ({
  profile,
  post,
  postArchive,
  useragent
}) => {
  const deviceOS = get(useragent, ['result', 'os', 'name']);
  const installUrl = deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl;
  const packageName = 'com.kakao.talk';
  let schemeHost = (profile && `plusfriend/home/${profile.id}`) ?? '';
  if (profile && postArchive) {
    schemeHost = `plusfriend/home/${profile.id}/archive?type=posts`;
  } else if (post) {
    schemeHost = `plusfriend/post/${post.author.id}/${post.id}?action=add_channel`;
  }
  const scheme =
    deviceOS === 'Android'
      ? `kakaoplus://${schemeHost}`
      : `${process.env.REACT_APP_PF_CUSTOM_SCHEME}://${schemeHost}`;

  return (
    <div>
      <button type="button" className="btn_app">
        <a
          href={scheme}
          onClick={e => {
            e.preventDefault();
            if (postArchive || post) {
              TiaraTrackUtils.trackClick({
                actionName: '앱열기_클릭',
                page: postArchive ? 'post_archive' : 'post',
                pageMeta: {id: profile?.profileId || post?.author?.profileId},
                click: {
                  layer1: 'header',
                  layer2: 'to_app',
                  click_url: undefined
                }
              });
            }
            openWithApp(
              deviceOS,
              'kakaoplus',
              schemeHost,
              packageName,
              installUrl
            );
          }}
        >
          앱열기
        </a>
      </button>
    </div>
  );
};

export default OpenAppButton;
