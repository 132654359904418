const openWithApp = (
  osCode: string,
  scheme: string,
  customUrl: string,
  packageName: string,
  appStoreUrl: string,
  useIntent: boolean = true
) => {
  const intentUrl = `intent://${customUrl}#Intent;scheme=${scheme};package=${packageName};end`;
  const customScheme = `${scheme}://${customUrl}`;
  if (osCode === 'Android') {
    window.location.href = useIntent ? intentUrl : customScheme;
  } else if (osCode === 'iOS') {
    const clickedAt = new Date();
    // 카카오톡내에서 실행된 경우 app 이 설치된 것으로 판단하고 appStoreUrl 띄우지 않음
    const uagent = navigator.userAgent.toLocaleLowerCase();
    if (uagent.search('kakaotalk') < 0) {
      setTimeout(function() {
        if (new Date().getTime() - clickedAt.getTime() < 2000) {
          // PFPROJ-306: 톡으로 이동후 다시 appstore로 넘어가는 현상때문에 임시로 주석처리
          //window.location = appStoreUrl;
        }
      }, 1500);
    }
    window.location.href = customScheme;
  } else {
    alert(`지원하지 않는 플랫폼입니다. (${osCode})`);
  }
};

export default openWithApp;
