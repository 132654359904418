import React from 'react';
import BaseModal from 'views/components/Modal/BaseModal';
import { Profile } from 'models/Profile';

interface Props {
  profile: Profile;
  link: string;
  onHideModal: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}
const AddFriendModal: React.FC<Props> = ({ profile, link, onHideModal }) => {
  const defaultStyle: ReactModal.Styles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -120
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9998,
      backgroundColor: 'rgba(0,0,0,0.75)',
      width: '100%',
      height: '100%'
    }
  };
  const btnOk = () => {
    window.location.href = link;
  };
  return (
    <BaseModal
      contentLabel="친구추가"
      onRequestClose={onHideModal}
      style={defaultStyle}
    >
      {{
        renderTitle: () => (
          <div>
            <span>{profile.name}</span>
          </div>
        ),
        renderContent: () => (
          <div>
            <p className="txt_layer">
              광고와 마케팅 메시지를 <br />
              카카오톡으로 받아 볼 수 있습니다.
            </p>
            <div className="app_btn type_btns" style={{ border: '0px' }}>
              <button type="button" className="btn_g" onClick={onHideModal}>
                <span className="inner_txt">취소</span>
              </button>
              <button
                type="button"
                className="btn_g btn_add"
                onClick={() => btnOk()}
              >
                <span className="ico_plus ico_channel_add"></span>
                채널추가
              </button>
            </div>
          </div>
        )
      }}
    </BaseModal>
  );
};

export default AddFriendModal;
