import React from 'react';
import classNames from 'classnames';
import PostProfileHeader from 'views/components/Post/PostProfileHeader';
import PostCountBar from 'views/components/Post/PostCounterBar';
import PostContent from 'views/components/Post/PostContent';
import ProfileBanner from './ProfileBanner';
import { Post } from 'models/Post';
import { ADULT_AUTH_VALID_TYPE } from '../../../state/modules/home/constants';
import PostAgeCover from 'views/components/Post/PostAgeCover';
import {
  isBlindContentType,
  isBlindTemporaryType
} from '../../../utils/postUtil';

interface Props {
  type: string;
  post: Post;
  onShowModal: Function;
  layerCode: Function;
  sortIdx?: number;
}

const PostCard: React.FC<Props> = ({
  type,
  post,
  onShowModal,
  layerCode,
  sortIdx
}) => {
  const profilePath = `/${post.author.profileId}`;
  const postPath = `/${post.author.profileId}/${post.id}`;
  const isFeed = type === 'feed';
  const blindType = post.blindType;
  const isAgeCovered =
    post.adultOnly &&
    post.author.authInfo.validType !== ADULT_AUTH_VALID_TYPE.VERIFIED;
  // 예외 blind의 경우는 cover view로 노출되면 안됨
  const isNormalView =
    !isAgeCovered ||
    isBlindContentType(blindType) ||
    isBlindTemporaryType(blindType);

  const renderCoverView = () => {
    return (
      <div
        className={classNames({
          wrap_post: isFeed,
          wrap_post_first: isFeed && sortIdx === 0
        })}
      >
        <PostProfileHeader
          post={post}
          isFeed={isFeed}
          profilePath={profilePath}
          postPath={postPath}
          layerCode={layerCode}
        />
        <PostAgeCover type={type} post={post} />
      </div>
    );
  };

  const renderContentView = () => {
    return (
      <div
        className={classNames({
          wrap_post: isFeed,
          wrap_post_first: isFeed && sortIdx === 0
        })}
      >
        <PostProfileHeader
          post={post}
          isFeed={isFeed}
          profilePath={profilePath}
          postPath={postPath}
          layerCode={layerCode}
        />
        <PostContent type={type} post={post} postPath={postPath} />
        {!isFeed && (
          <ProfileBanner
            profile={post.author}
            post={post}
            onShowModal={onShowModal}
            layerCode={layerCode}
          />
        )}
        <PostCountBar post={post} onShowModal={onShowModal} isFeed={isFeed} />
      </div>
    );
  };
  return isNormalView ? renderContentView() : renderCoverView();
};

export default PostCard;
