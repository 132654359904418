import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import head from 'lodash/head';
import { linkify } from 'utils/urlUtil';
import ImageView from 'views/components/Post/media/ImageView';
import ImageFeedView from 'views/components/Post/feed_media/ImageFeedView';
import LinkView from 'views/components/Post/media/LinkView';
import CardView from 'views/components/Post/media/CardView';
import CardFeedView from 'views/components/Post/feed_media/CardFeedView';
import CouponCardView from 'views/components/Post/media/CouponCardView';
import VideoView from 'views/components/Post/media/VideoView';
import VideoFeedView from 'views/components/Post/feed_media/VideoFeedView';
import { wordFormat } from 'utils/dateFormat';
import {
  isBlindContentType,
  isBlindPostContentOnly,
  isBlindTemporaryType
} from 'utils/postUtil';
import { Post } from 'models/Post';
import { Image, LinkScrap, Medium, PostImage, Video } from 'models/Medium';
import { TiaraTrackUtils } from '../../../utils/tiaraNewHelper';

const TEXT_LINE_HEIGHT = 22;
const TEXT_LINE_WITH_MEDIA_PC = 2;
const TEXT_LINE_NO_MEDIA_PC = 7;

interface Props {
  type: string;
  post: Post;
  postPath: string;
}

interface TextStyle {
  height?: number;
  display: string | undefined;
  overflow: string | undefined;
  hasMoreView: boolean;
}

const PostContent: React.FC<Props> = ({ type, post, postPath }) => {
  const { title, media } = post;
  const [textStyle, setTextStyle] = useState<TextStyle>({
    display: undefined,
    overflow: undefined,
    hasMoreView: false
  });

  const getDescription = (post: Post) => {
    return post.contents
      .filter(tv => tv.t !== 'sticker')
      .map(tv => tv.v)
      .join('');
  };

  const isEmptyMedia = (media: Medium[]) => {
    return media === undefined || media.length === 0;
  };

  const titleContainer = useRef<HTMLDivElement>(null);
  const textContainer = useRef<HTMLDivElement>(null);

  const calculateTextStyle = useCallback(() => {
    if (type === 'feed') {
      const nonMedia = !media || isEmptyMedia(media);
      const maxHeight = nonMedia
        ? TEXT_LINE_HEIGHT * TEXT_LINE_NO_MEDIA_PC
        : TEXT_LINE_HEIGHT * TEXT_LINE_WITH_MEDIA_PC;

      if (!titleContainer.current && !textContainer.current) {
        return;
      }

      const titleContainerWidth: number = titleContainer.current!.getBoundingClientRect()
        .width;
      const textContainerWidth = textContainer.current!.getBoundingClientRect()
        .width;
      const textContainerHeight = textContainer.current!.getBoundingClientRect()
        .height;

      const nonText =
        !linkify(post.contents) || getDescription(post).trim() === '';
      const textLineNumber = textContainerHeight / TEXT_LINE_HEIGHT;

      const isTitleOver = textContainerWidth < titleContainerWidth;
      const isTextOver = nonMedia
        ? textLineNumber > TEXT_LINE_NO_MEDIA_PC
        : textLineNumber > TEXT_LINE_WITH_MEDIA_PC;

      const textHeight = nonText ? 0 : Math.min(maxHeight, textContainerHeight);

      setTextStyle({
        height: textHeight,
        display: textContainerHeight > maxHeight ? 'inline-block' : undefined,
        overflow: textContainerHeight > maxHeight ? 'hidden' : undefined,
        hasMoreView: nonText ? isTitleOver : isTextOver
      });
    }
  }, [type, media, post, titleContainer, textContainer]);

  useEffect(() => {
    calculateTextStyle();
    window.addEventListener('resize', calculateTextStyle);

    return function cleanup() {
      window.removeEventListener('resize', calculateTextStyle);
    };
  }, [calculateTextStyle]);

  const isBlind = isBlindPostContentOnly(post.blindType);

  const renderFeedMedia = (post: Post) => {
    if (post.type === 'image') {
      return (
        <ImageFeedView
          images={post.media as Image[]}
          postPath={postPath}
          tiaraClickHandler={(clickIdx?: number) => {
            if (isFeed) {
              TiaraTrackUtils.trackClick({
                actionName: '이미지썸네일_클릭',
                page: 'post_archive',
                pageMeta: { id: post?.author?.profileId },
                click: {
                  layer1: 'post_body',
                  layer2: 'thumb',
                  ordNum: clickIdx
                }
              });
            }
          }}
        />
      );
    }
    if (post.type === 'link') {
      const link = head(post.media)! as LinkScrap;
      return (
        <div className="post_card">
          {' '}
          <LinkView
            type={type}
            link={link}
            tiaraClickHandler={() => {
              if (isFeed) {
                TiaraTrackUtils.trackClick({
                  actionName: '스크랩url_클릭',
                  pageMeta: { id: post?.author?.profileId },
                  page: 'post_archive',
                  click: {
                    layer1: 'post_body',
                    layer2: 'scrap_url'
                  }
                });
              }
            }}
          />{' '}
        </div>
      );
    }
    if (post.type === 'card') {
      const MAX_CARD_SIZE = 6;
      const PAGE_SIZE = 2;
      const PREFETCH_SIZE = 2;

      return (
        <CardFeedView
          cards={post.media}
          postPath={postPath}
          maxCardSize={MAX_CARD_SIZE}
          pageSize={PAGE_SIZE}
          prefetchSize={PREFETCH_SIZE}
          profileId={post?.author?.profileId}
        />
      );
    }
    if (post.type === 'video') {
      return (
        <VideoFeedView
          video={head(post.media) as Video}
          tiaraClickHandler={() => {
            if (isFeed) {
              TiaraTrackUtils.trackClick({
                actionName: '영상재생_클릭',
                page: 'post_archive',
                pageMeta: { id: post?.author?.profileId },
                click: {
                  layer1: 'post_body',
                  layer2: 'play'
                }
              });
            }
          }}
        />
      );
    }
  };

  const renderMedia = (post: Post) => {
    let result: JSX.Element[] = [];
    post.media?.forEach((medium, index) => {
      if (!medium) {
        console.error('MEDIUM NOT FOUND!!', post.id, index);
        return null;
      }
      if (medium.type === 'image') {
        result.push(<ImageView key={index} image={medium as PostImage} />);
      }
      if (medium.type === 'link') {
        result.push(
          <LinkView key={index} type={type} link={medium as LinkScrap} />
        );
      }
      if (medium.type === 'card') {
        result.push(<CardView key={index} card={medium} />);
      }
      if (medium.type === 'couponCard') {
        result.push(<CouponCardView key={index} coupdonCard={medium} />);
      }
      if (medium.type === 'video') {
        result.push(<VideoView key={index} video={medium as Video} />);
      }
    });

    return <div className="post_card">{result}</div>;
  };

  const isFeed = type && type === 'feed';

  return (
    <div>
      <div className="post_txt">
        <strong className="tit_post">
          <span ref={titleContainer}>{isBlind ? '' : title}</span>
        </strong>
        {isFeed ? (
          <p
            className="desc_post"
            ref={textContainer}
            style={{
              maxHeight:
                (media
                  ? TEXT_LINE_HEIGHT * TEXT_LINE_WITH_MEDIA_PC
                  : TEXT_LINE_HEIGHT * TEXT_LINE_NO_MEDIA_PC) +
                2 * TEXT_LINE_HEIGHT,
              overflow: 'hidden'
            }}
          >
            <span style={textStyle}>
              <PostContentText post={post} />
            </span>
            {textStyle.hasMoreView && (
              <Link
                to={postPath}
                target="_self"
                className="link_more"
                style={{ display: 'block', paddingTop: 0 }}
                onClick={() => {
                  if (isFeed) {
                    TiaraTrackUtils.trackClick({
                      actionName: '포스트내용_클릭',
                      page: 'post_archive',
                      pageMeta: { id: post?.author?.profileId },
                      click: {
                        layer1: 'post_body',
                        layer2: 'contents'
                      }
                    });
                  }
                }}
              >
                …더보기
              </Link>
            )}
          </p>
        ) : (
          <p className="desc_post">
            <PostContentText post={post} />
          </p>
        )}
      </div>
      {!isBlind &&
        !isEmptyMedia(post.media) &&
        (isFeed ? renderFeedMedia(post) : renderMedia(post))}
    </div>
  );
};

const PostContentText: React.FC<{ post: Post }> = ({ post }) => {
  const { blindType, blindedAt, contents } = post;
  // 블라인드
  if (isBlindContentType(blindType)) {
    return (
      <>
        해당 게시글은 카카오톡 채널 운영정책에서 제한하고 있는 내용이 포함되어
        있음이 신고되어 {`${wordFormat(new Date(blindedAt!))}`}에 블라인드
        조치되었습니다.
        <br />
        <br />
        [블라인드 조치 대상] <br />
        • 음란성/도박성 등 불법성 게시물 <br />
        • 청소년 유해물 <br />
        • 비방/욕설이 포함된 게시물 <br />
        • 도배(친구신청 및 공유요청 등)게시물 <br />
        • 단순 광고,홍보성 게시물
        <br />
        <br />
        채널은 서비스 운영정책 및 청소년 보호 법률 준수를 위해 게시글이 블라인드
        될 수 있습니다.
      </>
    );
  }

  // 임시 조치
  if (isBlindTemporaryType(blindType)) {
    return (
      <>
        해당 게시글은 권리침해신고되어 {`${wordFormat(new Date(blindedAt!))}`}에
        임시조치되었습니다.
        <br />
        <br />
        1. 권리침해 신고글 임시조치 안내 <br />
        권리침해신고는 '정보통신망법 제44조의 2(정보의 삭제요청 등)'과 저작권법
        103조(복제, 전송의 중단)에 의거하여 운영되는 서비스입니다.
        <br />
        <br />
        2. 권리침해 신고 대상 <br />
        • 명예훼손 및 개인정보침해 <br />
        • 저작권 침해
        <br />
        <br />
        권리침해 신고안내 (
        <a href="http://www.kakao.com/policy/right">
          http://www.kakao.com/policy/right
        </a>
        )
      </>
    );
  }

  return <>{linkify(contents)}</>;
};

export default PostContent;
