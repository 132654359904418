import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/modules';
import { Call2Action, Call2ActionType } from 'models/Profile';
import { isEmpty, get } from 'lodash';

interface Props {
  link: Call2Action;
}
const HomeTabCall2ActionButton: React.FC<Props> = ({ link }) => {
  const useragent = useSelector((state: RootState) => state.home.useragent);

  const handleClickAction = () => {
    const deviceOS = get(useragent, ['result', 'os', 'name']);
    const isPC = deviceOS !== 'Android' && deviceOS !== 'iOS';

    if (isPC) {
      if (!isEmpty(link?.urls?.web)) {
        window.open(link?.urls?.web, '_blank');
      } else {
        if (
          link?.type === Call2ActionType.qr_code ||
          link?.type === Call2ActionType.app ||
          link?.type === Call2ActionType.membership
        ) {
          alert('PC에서는 사용하실 수 없습니다.\n모바일에서 확인해 주세요.');
        }
      }
    } else {
      const installUrl =
        deviceOS === 'Android'
          ? link?.urls?.androidInstall
          : link?.urls?.iosInstall;
      const executeUrl =
        deviceOS === 'Android'
          ? link?.urls?.androidExecute
          : link?.urls?.iosExecute;
      const splited = executeUrl?.split('://') || ['', ''];
      const intentUrl = `intent://${splited[1]}#Intent;scheme=${splited[0]};end`;

      if (!!installUrl) {
        const clickedAt = +new Date();
        setTimeout(() => {
          if (+new Date() - clickedAt < 2000) {
            window.location.href = installUrl;
          }
        }, 1500);
      }
      if (!!executeUrl) {
        window.location.href =
          (deviceOS === 'Android' ? intentUrl : executeUrl) || '';
      } else if (!isEmpty(link?.urls?.web)) {
        window.open(link?.urls?.web, '_blank');
      }
    }
  };

  const renderActionButtonLabel = (type: string) => {
    if (type === Call2ActionType.more) {
      return (
        <>
          <span className="ico_plus ico_more" />더 알아보기
        </>
      );
    } else if (type === Call2ActionType.app) {
      return (
        <>
          <span className="ico_plus ico_use" />앱 사용하기
        </>
      );
    } else if (type === Call2ActionType.order) {
      return (
        <>
          <span className="ico_plus ico_order" />톡 주문하기
        </>
      );
    } else if (type === Call2ActionType.store) {
      return (
        <>
          <span className="ico_plus ico_store" />
          스토어
        </>
      );
    } else if (type === Call2ActionType.membership) {
      return (
        <>
          <span className="ico_plus ico_membership" />
          멤버십
        </>
      );
    } else if (type === Call2ActionType.qr_code) {
      return (
        <>
          <span className="ico_plus ico_scan" />
          코드스캔
        </>
      );
    } else if (type === Call2ActionType.shopping_mall) {
      return (
        <>
          <span className="ico_plus ico_mall" />
          쇼핑몰
        </>
      );
    }
    return null;
  };

  return (
    <button type="button" className="btn_action " onClick={handleClickAction}>
      {/* 버튼 press 시 active 클래스 추가 */}
      {renderActionButtonLabel(link?.type)}
    </button>
  );
};

export default HomeTabCall2ActionButton;
