import React from 'react';
import { LeverageTabInfo } from 'models/LeverageTemplate';

interface Props {
  info: LeverageTabInfo;
}
const HomeTabInfoItem: React.FC<Props> = ({ info }) => {
  const isLink = !!info?.link;
  const linkType = info?.link?.type;
  const text = info?.description?.text;
  return (
    <>
      <dt>{info?.title?.text}</dt>
      <dd>
        {linkType === 'web' && (
          <a
            href={info?.link?.url}
            className="link_g"
            rel="noopener noreferrer"
            target="_blank"
          >
            {text}
          </a>
        )}
        {linkType === 'email' && (
          <a href={`mailto:${info?.link?.url}`} className="link_g">
            {text}
          </a>
        )}
        {linkType === 'tel' && (
          <>
            <a href={`tel:${info?.link?.url}`} className="link_g">
              {text}
            </a>
            {info?.description?.isCharged && '(유료) '}
          </>
        )}
        {!isLink && text}
      </dd>
    </>
  );
};

export default HomeTabInfoItem;
