import React from 'react';
import { LeverageTabHeader } from 'models/LeverageTemplate';
interface Props {
  header: LeverageTabHeader;
  children?: any;
  outerNodeMode?: boolean;
}
const HomeTabHeader: React.FC<Props> = ({
  header,
  children,
  outerNodeMode = false
}) => {
  return (
    <>
      <div className="area_tit">
        <strong className="tit_g">{header?.title?.text}</strong>
        {!outerNodeMode && children}
      </div>
      {outerNodeMode && children}
    </>
  );
};

export default HomeTabHeader;
