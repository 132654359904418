import React from 'react';
import BaseModal from './BaseModal';
import { getImageUrl } from 'utils/imageUtils';
import { Post } from 'models/Post';
import { Image } from 'models/Medium';

interface Props {
  post: Post;
  profileImage: Image;
  coverImage: Image;
  onHideModal: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}
const ProfileImageModal: React.FC<Props> = ({ onHideModal, profileImage, coverImage }) => {
  const MAX_WIDTH = window.innerWidth >= 560 ? 560 : window.innerWidth * 0.7;
  const image = profileImage ? profileImage : coverImage;

  const width = image.width < MAX_WIDTH ? image.width : MAX_WIDTH;
  const height =
    image.width < MAX_WIDTH
      ? image.height
      : image.height * (MAX_WIDTH / image.width);

  const defaultStyle: ReactModal.Styles = {
    content: {
      position: 'absolute',
      backgroundColor: 'transparent',
      border: 0,
      width: width,
      top: '50%',
      left: '50%',
      marginTop: -(height / 2),
      marginLeft: -(width / 2)
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9998,
      backgroundColor: 'rgba(0,0,0,0.75)',
      width: '100%',
      height: '100%'
    }
  };

  return (
    <BaseModal
      contentLabel="프로필 사진보기"
      onRequestClose={onHideModal}
      style={defaultStyle}
    >
      {{
        renderContent: () => (
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <div className="view_thumb">
              <img
                src={getImageUrl(image.url)}
                className="img_thumb"
                alt=""
                style={{ maxWidth: MAX_WIDTH }}
              />
            </div>
            <button
              type="button"
              className="btn_close"
              onClick={onHideModal}
              style={{
                position: 'absolute',
                top: -33,
                right: -6,
                width: 28,
                height: 28
              }}
            >
              <span className="ico_plus ico_close">레이어 닫기</span>
            </button>
          </div>
        ),
        isButtonInside: true
      }}
    </BaseModal>
  );
};

export default ProfileImageModal;
