import React, { useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { RootState } from 'state/modules';
import {
  LeverageTabModule,
  LeverageTabChatKeyword
} from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import HomeTabHeader from './HomeTabHeader';
import { sortBy } from 'lodash';
import openWithApp from 'utils/openWithApp';
import { androidInstallUrl, iosInstallUrl } from 'state/modules/home/constants';

interface Props {
  profile: Profile;
  module: LeverageTabModule;
}
const ModuleChatKeyword: React.FC<Props> = ({ profile, module }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const refSlideChatKeyword = useRef<Slider>(null);
  const useragent = useSelector((state: RootState) => state.home.useragent);
  const itemKeywords: Array<LeverageTabChatKeyword> =
    module?.contents[0]?.items;

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => setSlideIndex(index)
  };

  const isPC = useMemo(() => {
    const deviceOS = useragent?.result?.os?.name;
    return deviceOS !== 'Android' && deviceOS !== 'iOS';
  }, [useragent]);

  const handleOpenChat = (e: any, keyword: string) => {
    e.preventDefault();
    if (isPC) {
      window.open(`/${profile.profileId}/chat`, '_blank');
    } else {
      const deviceOS = useragent?.result?.os?.name || '';
      const encodedMessage = encodeURIComponent(keyword);
      openWithApp(
        deviceOS,
        deviceOS === 'iOS'
          ? process.env.REACT_APP_PF_CUSTOM_SCHEME || 'kakaoplus'
          : 'kakaoplus',
        `plusfriend/talk/bot/${profile.id}/${encodedMessage}`,
        'com.kakao.talk',
        deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl
      );
    }
  };

  const keywords: Array<Array<string>> = useMemo(() => {
    const data: Array<Array<string>> = [];
    if (itemKeywords.length > 0) {
      const keywords =
        sortBy(
          itemKeywords?.map(k => k.keyword),
          'sort'
        ) ?? [];
      let idx = 0;
      keywords?.forEach(keyword => {
        if (!data[idx]) {
          data[idx] = [];
        }
        if (data[idx]?.length === 4) {
          idx = idx + 1;
          data[idx] = [];
        }
        data[idx].push(keyword);
      });
    }
    return data;
  }, [itemKeywords]);

  return (
    <div className="wrap_home wrap_home_chatting2">
      <HomeTabHeader header={module?.header} />
      <div className="wrap_slide">
        <div className="area_slide">
          <ul className="list_slide">
            <Slider {...settings} ref={refSlideChatKeyword}>
              {keywords?.map((item, idx) => {
                return (
                  <li key={idx}>
                    <ul className="list_chatting">
                      {item?.map((keyword, idx) => {
                        return (
                          <li key={idx}>
                            <a
                              href="#none"
                              className="link_item"
                              onClick={e => handleOpenChat(e, keyword)}
                            >
                              <div className="info_item">
                                <span className="txt_g">{keyword}</span>
                              </div>
                              <span className="ico_plus ico_more" />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </Slider>
          </ul>
        </div>
        {keywords.length > 1 && (
          <div className="area_paging">
            <a
              href="#none"
              className="btn_page btn_prev"
              onClick={() => refSlideChatKeyword?.current?.slickPrev()}
            >
              <span className="ico_plus">이전</span>
            </a>
            <div className="inner_paging">
              {keywords.map((item, idx) => {
                if (idx === slideIndex) {
                  return (
                    <em key={idx} className="ico_dot">
                      <span className="screen_out">현재페이지 ${idx + 1}</span>
                    </em>
                  );
                }
                return (
                  <span key={idx} className="ico_dot">
                    <span className="screen_out">{idx + 1}</span>
                  </span>
                );
              })}
            </div>
            <a
              href="#none"
              className="btn_page btn_next"
              onClick={() => refSlideChatKeyword?.current?.slickNext()}
            >
              <span className="ico_plus">다음</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModuleChatKeyword;
