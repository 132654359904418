import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './modules'; // import all reducers from modules/index.js

export default function configureStore(initialState = {}) {
  const devTools =
    process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();
  const middleware = applyMiddleware(thunk);
  const enhancer = devTools ? compose(middleware, devTools) : middleware;

  return createStore(rootReducer, initialState, enhancer);
}
