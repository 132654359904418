import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import getImageUrl from 'utils/getImageUrl';
import VideoPlayer from 'views/components/Post/media/VideoPlayer';
import { Video } from 'models/Medium';

const RATIO = 16 / 9;

interface Props {
  video: Video;
  tiaraClickHandler: Function;
}
const VideoFeedView: React.FC<Props> = ({ video, tiaraClickHandler }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [containerStyle, setContainerStyle] = useState({
    width: 520,
    height: 520 / RATIO
  });
  const thumbnailStyle = useMemo(() => {
    return { width: 'auto', height: containerStyle.height };
  }, [containerStyle]);

  const handlePlayButtonClicked = () => {
    setIsPlaying(true);
    if (tiaraClickHandler) {
      tiaraClickHandler();
    }
  };

  const container = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!container.current) {
      return;
    }
    const calculateSize = () => {
      if (!container.current) return;
      const parentContainer = container.current.parentNode as HTMLElement;
      if (!parentContainer) return;
      const parentContainerRect = parentContainer.getBoundingClientRect();
      setContainerStyle({
        width: parentContainerRect.width,
        height: parentContainerRect.width / RATIO
      });
    };

    calculateSize();
    window.addEventListener('resize', calculateSize);

    return () => window.removeEventListener('resize', calculateSize);
  }, [container, isPlaying]);

  if (isPlaying) {
    return (
      <div className="post_card">
        <VideoPlayer video={video} />
      </div>
    );
  }

  const isLive = get(video, 'liveLinkId') > 0;
  return (
    <div className="post_card">
      <div className="card_default card_vod">
        <Link
          to="#"
          className="link_card"
          ref={container}
          onClick={handlePlayButtonClicked}
          style={{ height: 'auto' }}
        >
          <div className="wrap_thumb" style={containerStyle}>
            <img
              src={getImageUrl(video.thumbnail)}
              className="img_thumb"
              alt={video.name}
              style={thumbnailStyle}
            />
            <span className="frame_g" />
          </div>
          {isLive && <span className="ico_plus ico_live">LIVE</span>}
          <span className="ico_plus ico_play">Play</span>
        </Link>
      </div>
    </div>
  );
};

export default VideoFeedView;
