import React from 'react';
import head from 'lodash/head';
import last from 'lodash/last';
import get from 'lodash/get';
import { direction, COMMENT } from 'state/modules/home/constants';
import CommentItem from 'views/components/Post/comment/CommentItem';
import { useSelector } from 'react-redux';
import { Post } from 'models/Post';
import { RootState } from 'state/modules';
import { isMobile } from 'utils/deviceUtils';

interface Props {
  post: Post;
  fetchComments: Function;
  onShowModal: Function;
}
const CommentList: React.FC<Props> = ({ post, fetchComments, onShowModal }) => {
  const useragent = useSelector<RootState, UAParser.IResult>(
    state => state.home.useragent.result
  );
  const mobile = isMobile(useragent);

  const items = post.comments?.items || [];
  const hasNext = post.comments?.hasNext;
  const fetchDirection = get(post.comments, 'direction', direction.backward);
  const getFistComment = () => {
    fetchComments(post.author, post.id, { direction: direction.forward });
  };

  return (
    <div>
      <strong className="screen_out">댓글영역</strong>
      {hasNext && fetchDirection === direction.backward && (
        <div className="cmt_btn">
          <button
            type="button"
            className="btn_cmt"
            onClick={() =>
              fetchComments(post.author.profileId, post.id, {
                since: head(items)?.id,
                direction: fetchDirection
              })
            }
          >
            <span className="inner_txt">
              <span className="ico_plus ico_prev" />
              이전 댓글
            </span>
          </button>
          <button
            type="button"
            className="btn_cmt"
            onClick={() => getFistComment()}
          >
            <span className="inner_txt">
              <span className="ico_plus ico_fst" />
              처음 댓글
            </span>
          </button>
        </div>
      )}
      <div className="cmt_bundle">
        {!post.commentable && (
          <p className="desc_notify">댓글을 작성 할 수 없는 소식입니다.</p>
        )}
        {post.commentable && items.length === 0 ? (
          <p className="desc_empty">
            등록된 댓글이 없습니다.
            <br />
            가장 먼저 댓글을 남겨보세요.
          </p>
        ) : (
          items.map((comment, idx) => (
            <CommentItem comment={comment} key={idx} />
          ))
        )}
      </div>
      {post.commentable && hasNext && fetchDirection === direction.forward && (
        <button
          type="button"
          className="btn_more"
          onClick={() =>
            fetchComments(post.author.profileId, post.id, {
              since: last(items)?.id,
              direction: fetchDirection
            })
          }
        >
          <span className="ico_plus" />
          댓글 더보기
        </button>
      )}
      {post.commentable && (
        <div className="box_appinfo">
          <button
            type="button"
            className="btn_appinfo"
            onClick={() => mobile && onShowModal(COMMENT, { post })}
            style={{ cursor: 'default' }}
          >
            <span className="inner_txt">
              <span className="ico_plus ico_kakao" />
              <span
                className="txt_info"
                style={{ display: mobile ? 'none' : 'inline' }}
              >
                댓글 입력은 카카오톡 모바일 앱에서 가능합니다.
              </span>
              <span
                className="txt_info2"
                style={{ display: mobile ? 'inline' : 'none' }}
              >
                카카오톡앱에서 댓글 달기
              </span>
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CommentList;
