import get from 'lodash/get';
import { Image } from 'models/Medium';

export function getSquircleThumbnailUrl(url: string | undefined) {
  return url
    ? `https://img1.daumcdn.net/thumb/C100x100.mplusfriend/?fname=${encodeURIComponent(
        getValidUrl(url)
      )}`
    : url;
}

export function getValidUrl(url: string) {
  return url.startsWith('//') ? 'https:'.concat(url) : url;
}

export function getImageUrl(imgUrl: string) {
  // image 가 http일 경우 https 만 허용하는 브라우저에서는 깨져보이기 때문에 처리
  // 추후에 서버에서 https 로 내려주는 방식으로 수정해야 함.
  return imgUrl.replace(/^http:\/\//i, '//');
}

export const isGif = (image: Image) => {
  const fileName = get(image, 'filename');
  return fileName && fileName.indexOf('.gif') > 0;
};
