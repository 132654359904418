import React from 'react';
import { Profile } from 'models/Profile';
import { sortBy } from 'lodash';
import {
  LeverageTab,
  LeverageTabModule,
  LeverageTabModuleType
} from 'models/LeverageTemplate';
import ModuleNotice from './ModuleNotice';
import ModuleCoupon from './ModuleCoupon';
import ModuleSimplePost from './ModuleSimplePost';
import ModuleChat from './ModuleChat';
import ModuleChatKeyword from './ModuleChatKeyword';
import ModuleProfileInfo from './ModuleProfileInfo';
import ModuleBusinessInfo from './ModuleBusinessInfo';
import ModuleMap from './ModuleMap';
interface Props {
  profile: Profile;
  hometab: LeverageTab;
  changeTab: Function;
}
const HomeTab: React.FC<Props> = ({ profile, hometab, changeTab }) => {
  const renderItem = (leverageTagModule: LeverageTabModule, index: number) => {
    let Module: any = undefined;
    if (leverageTagModule.type === LeverageTabModuleType.notice) {
      Module = ModuleNotice;
    } else if (leverageTagModule.type === LeverageTabModuleType.coupon) {
      Module = ModuleCoupon;
    } else if (leverageTagModule.type === LeverageTabModuleType.commerce) {
      // SKIP: 웹홈에서는 미노출
    } else if (leverageTagModule.type === LeverageTabModuleType.simple_post) {
      Module = ModuleSimplePost;
    } else if (leverageTagModule.type === LeverageTabModuleType.chat) {
      Module = ModuleChat;
    } else if (leverageTagModule.type === LeverageTabModuleType.keyword_chat) {
      Module = ModuleChatKeyword;
    } else if (leverageTagModule.type === LeverageTabModuleType.map) {
      Module = ModuleMap;
    } else if (leverageTagModule.type === LeverageTabModuleType.text_info) {
      Module = ModuleProfileInfo;
    } else if (leverageTagModule.type === LeverageTabModuleType.business) {
      Module = ModuleBusinessInfo;
    }

    if (!!Module) {
      return (
        <Module
          key={index}
          module={leverageTagModule}
          profile={profile}
          changeTab={changeTab}
        />
      );
    }
    return null;
  };

  const renderHomeTab = () => {
    if (!!hometab?.items) {
      return sortBy(hometab?.items, 'sort')
        ?.map((ltm, idx) => renderItem(ltm, idx))
        .concat(
          <div className="post_copyright">
            <p className="desc_copyright">
              이 정보는 채널 운영자가 등록한 정보입니다.
            </p>
            <span className="screen_out">Copyright</span>
            <span className="ico_plus">플러스친구</span>
          </div>
        );
    }

    return <p className="desc_none">채널의 정보가 없습니다.</p>;
  };

  return (
    <div>
      <strong className="screen_out">홈</strong>
      {renderHomeTab()}
    </div>
  );
};

export default HomeTab;
