import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from 'state/store';
import createRoutes from 'routes';
import 'index.css';
import App from 'views/App';
import * as serviceWorker from 'serviceWorker';
import { initTiara } from 'utils/tiaraHelper';
import { initSentry } from 'utils/sentryHelper';
import * as Sentry from '@sentry/browser';

// initial dummmy data
window.REDUX_INITIAL_DATA = {
  home: {
    entities: {}
  }
};

initTiara();
initSentry();

try {
  const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

  ReactDOM.render(
    <ReduxProvider store={reduxStore}>
      <App router={<BrowserRouter>{createRoutes()}</BrowserRouter>} />
    </ReduxProvider>,
    document.getElementById('root')
  );
} catch (e) {
  console.log('eeeeeee', e);
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.captureException(e);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
