import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { isBizpluginUrl, linkify } from 'utils/urlUtil';
import CardImageHolder from 'views/components/Post/media/CardImageHolder';
import { useRouteMatch } from 'react-router-dom';
import { isMobile } from 'utils/deviceUtils';
import { RootState } from 'state/modules';
import { useSelector } from 'react-redux';

interface Props {
  card: any; // TODO: 카드 모델로 바꾸자
}
const CardView: React.FC<Props> = ({ card }) => {
  const { title, contents, button, medium, viewType } = card;
  const match = useRouteMatch();

  const mobile = isMobile(
    useSelector(
      (state: RootState) => {
        return state.home.useragent.result;
      },
      () => false
    )
  );

  const getButtonHref = () => {
    if (get(button, 'linkType') === 'phone') return null;
    const originalLink = get(button, 'linkTo');
    if (!mobile && isBizpluginUrl(originalLink)) {
      return `${window.location.origin}/${
        (match?.params as any)?.encodedProfileId
      }/bridge?type=none`;
    }
    return originalLink;
  };

  return (
    <div
      className={classNames('card_default', 'card_view', {
        card_btn: !isEmpty(button)
      })}
    >
      <div className="inner_card">
        <div className="wrap_thumb">
          <CardImageHolder image={medium} viewType={viewType} />
          {!isEmpty(button) ? (
            <a href={getButtonHref()} target="_blank" rel="noopener noreferrer">
              <button
                type="button"
                className="btn_tel"
                style={{ position: 'static' }}
                onClick={() => {
                  get(button, 'linkType') === 'phone' &&
                    alert(`전화번호는 ${get(button, 'linkTo')} 입니다.`);
                }}
              >
                <span className="inner_txt">{get(button, 'title')}</span>
              </button>
            </a>
          ) : null}
        </div>
        <div className="info_cont">
          <strong className="tit_card">{title}</strong>
          {!isEmpty(contents) ? (
            <div className="desc_card">{linkify(contents)}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CardView;
