// import { TabType } from 'views/components/Tab';
import { Image } from './Medium';

export interface Profile {
  searchable: string; // 'ON';
  coverImage: Image;
  siteUrl: string; // '';
  name: string; // '연서아범';
  permalink: string; // 'http://sandbox-pf.kakao.com/_jzM';
  phone: string; // '';
  postCount: number;
  isAdult: boolean; // false;
  isBlocked: boolean;
  isChatBot: boolean;
  isOfficial: boolean;
  isOpen: boolean;
  friendCount: number; // 26;
  isFriend?: boolean;
  friendAt?: number;
  visible: string; // 'ON';
  profileImage: Image;
  statusMessage: string; // '안녕하세요안녕하세요안녕하세요';
  verificationType: string; // 'NONE';
  verificationGuide: VerificationGuide;
  id: number;
  uuid: string; // '@연서아범2';
  category: ProfileCategory;
  profileBot: ProfileBot;
  profileId: string; // '_xcBxnxah';
  spamLevel: number; // 0;
  businessPartnerInfo?: any;
  email: string;
  address: any;
  call2action?: Call2Action;
  homeDefaultTab: TabType;
  authInfo: AuthInfo;
  isPhoneCharged: boolean;
  //   posts
}

export enum TabType {
  hometab = 'hometab',
  post = 'post'
}

export interface VerificationGuide {
  title: string;
  msg: string;
  metaType: string;
}

export interface Call2Action {
  type: Call2ActionType;
  urls?: Call2ActionUrl;
  tel?: string;
  connected?: boolean;
}

export enum Call2ActionType {
  more = 'more',
  app = 'app',
  order = 'order',
  store = 'store',
  membership = 'membership',
  qr_code = 'qr_code',
  shopping_mall = 'shopping_mall'
}

export interface Call2ActionUrl {
  web: string;
  androidInstall?: string;
  androidExecute?: string;
  iosInstall?: string;
  iosExecute?: string;
}

export interface ProfileCategory {
  id: number; // 24060;
  parentId: number; // 24000;
  name: string; // '대학교/대학원';
  createdAt: number; // 1513319059000;
  isActive: boolean; // true;
  isVisible: boolean; // true;
  parentName: string; // '교육';
}

export interface ProfileBot {
  id: number; //26081331
  botType: 'kwd' | 'ext';
  isBotBuilder: boolean; // false
  salt: string; // "1"
  sendMessageFlag: boolean; // flag
  status: string; // 'R'
  createdAt: number; // 1478096816000
  updatedAt: number; // 1528670570000
}

export interface BusinessPartnerInfo {
  identityNumber: string; // '120-86-39351'
  name: string; // '(주) 디엠씨미디어'
  businessItem: string; // '광고마케팅서비스대행및제작, 전자상거래업 외'
  type: string; // 'BUSINESS'
  typeItem: string; // '서비스, 소매'
  zipcode: string; //'06069'
  representativeName: string; //'이준희'
  address: string;
}

export interface AuthInfo {
  ageAuthUrl: string; // 'https://auth.kakao.com/ageauths/main.html?auth_level=20&auth_from=pf&return_url=http://pf.kakao.com/_xcBxnxah'
  validType: string;
}
