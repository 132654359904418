import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import last from 'lodash/last';
import { AGE_AUTH, ADULT_AUTH_VALID_TYPE } from 'state/modules/home/constants';
import { homeSelectors, homeOperations } from 'state/modules/home';
import { showModal } from 'state/modules/home/actions';
import ContentWrapper from 'views/components/ContentWrapper';
import Feed from 'views/components/Feed';
import Gnb from 'views/components/Gnb';
import { useTiaraPage } from 'utils/tiaraNewHelper';
import { Profile } from 'models/Profile';
import { Post } from 'models/Post';
import { RootState } from 'state/modules';
import UrlCopyLayer from '../components/UrlCopyLayer';

interface Props {
  profileId: string;
  profile: Profile;
  posts: Post[];
  onShowModal: Function;
  fetchProfile: Function;
  fetchPosts: Function;
  error: any;
}

const PostArchivePage: React.FC<Props> = ({
  profileId,
  profile,
  posts,
  onShowModal,
  fetchProfile,
  fetchPosts,
  error
}) => {
  const [copyUrlVisibility, setCopyUrlVisibility] = useState('none');
  const debouncedHandleScroll = debounce(handleScroll, 500);
  const hasNext = get(profile, ['posts', 'hasNext']);

  useTiaraPage({
    profileId,
    actionName: '소식아카이브_조회',
    page: 'post_archive'
  });

  // 1회만 조회
  useEffect(() => {
    fetchProfile(profileId);
  }, [fetchProfile, profileId]);

  const layerCode = (code: string) => {
    setCopyUrlVisibility(code);
  };

  const contents = useMemo(() => {
    const defaultContents = (
      <>
        <Gnb profile={profile} postArchive={true} />
        <ContentWrapper accessbility={{ content: '프로필 본문영역' }}>
          <Feed
            profile={profile}
            posts={posts}
            hasNext={hasNext}
            onShowModal={onShowModal}
            layerCode={layerCode}
          />
        </ContentWrapper>
        <UrlCopyLayer completedLayer={copyUrlVisibility} profile={profile} />
      </>
    );

    return defaultContents;
  }, [profile, onShowModal, posts, hasNext, copyUrlVisibility]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', debouncedHandleScroll);
    }
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  });

  useEffect(() => {
    if (!profile) {
      return;
    }

    const isForAdult = profile.isAdult;
    const validType = get(
      profile,
      'authInfo.validType',
      ADULT_AUTH_VALID_TYPE.UNAUTHORIZED
    );

    if (isForAdult && validType !== ADULT_AUTH_VALID_TYPE.VERIFIED) {
      onShowModal(AGE_AUTH, { profile });
    }
  }, [profile, onShowModal, profileId]);

  if (!profile) {
    if (error?.response?.status === 404) {
      window.location.href = '/';
    }
    return <></>;
  }

  function handleScroll() {
    const scrollNode = document.scrollingElement
      ? (document.scrollingElement as HTMLElement)
      : document.documentElement;
    const scrollHeight = scrollNode.scrollHeight;
    const scrollTop = scrollNode.scrollTop;
    const offsetHeight = scrollNode.offsetHeight;
    if (hasNext && scrollHeight - 2 * offsetHeight < scrollTop) {
      fetchPosts(profileId, { since: last(posts)?.sort ?? undefined });
    }
  }

  return <>{contents}</>;
};

function mapStatetoProps(state: RootState, props: any) {
  const {
    home: { error }
  } = state;

  const {
    match: {
      params: { encodedProfileId: profileId }
    }
  } = props;

  return {
    error: error.error,
    profileId,
    profile: homeSelectors.getProfile(state, props),
    posts: homeSelectors.getProfilePosts(state, props)
  };
}

export default connect(mapStatetoProps, dispatch =>
  bindActionCreators(
    {
      onShowModal: showModal,
      fetchProfile: homeOperations.fetchProfile,
      fetchPosts: homeOperations.fetchPosts
    },
    dispatch
  )
)(PostArchivePage);
