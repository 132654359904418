import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { LeverageTabModule, LeverageTabNotice } from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import HomeTabHeader from './HomeTabHeader';
import 'styles/slick.css';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

const DEFAULT_TEXT = '공지사항을 알려드립니다.';
interface Props {
  profile: Profile;
  module: LeverageTabModule;
}
const ModuleNotice: React.FC<Props> = ({ profile, module }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const refSlideNotice = useRef<Slider>(null);
  const items: Array<LeverageTabNotice> = module?.contents[0]?.items;

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => setSlideIndex(index)
  };

  return (
    <div className="wrap_home wrap_home_notice">
      <HomeTabHeader header={module?.header} />
      <div className="wrap_slide">
        <div className="area_slide">
          <ul className="list_slide">
            <Slider {...settings} ref={refSlideNotice}>
              {items.map((item, idx) => {
                const isVideo = item?.media?.type === 'video';
                const isLink = item?.media?.type === 'link';
                let image = item?.media?.image;
                if (isVideo) {
                  image = item?.media?.video?.thumbnail;
                } else if (isLink) {
                  image = item?.media?.link?.image;
                }
                return (
                  <li key={idx}>
                    <Link
                      to={`/${profile?.profileId}/${item?.postId}`}
                      className="item_slide"
                      draggable="false"
                      style={{ height: 62 }}
                    >
                      {!!image?.imageUrl && (
                        <span className="thumb_item">
                          {isVideo && (
                            <span className="ico_plus ico_play">재생</span>
                          )}
                          <img
                            src={image?.imageUrl}
                            className="img_thumb"
                            alt=""
                          />
                        </span>
                      )}
                      <div className="cont_item">
                        <p className="desc_g">
                          {isEmpty(item?.title?.text) &&
                            isEmpty(item?.description?.text) &&
                            DEFAULT_TEXT}
                          {item?.title?.text}
                          {!isEmpty(item?.title?.text) &&
                            !isEmpty(item?.description?.text) && <br />}
                          {item?.description?.text}
                        </p>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </Slider>
          </ul>
        </div>
        {items.length > 1 && (
          <div className="area_paging">
            <a
              href="#none"
              className="btn_page btn_prev"
              onClick={() => refSlideNotice?.current?.slickPrev()}
            >
              <span className="ico_plus">이전</span>
            </a>
            <div className="inner_paging">
              {items.map((item, idx) => {
                if (idx === slideIndex) {
                  return (
                    <em key={idx} className="ico_dot">
                      <span className="screen_out">현재페이지 ${idx + 1}</span>
                    </em>
                  );
                }
                return (
                  <span key={idx} className="ico_dot">
                    <span className="screen_out">{idx + 1}</span>
                  </span>
                );
              })}
            </div>
            <a
              href="#none"
              className="btn_page btn_next"
              onClick={() => refSlideNotice?.current?.slickNext()}
            >
              <span className="ico_plus">다음</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModuleNotice;
