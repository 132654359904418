import { homeTypes } from '.';

export function showModal(modalType: any, modalProps: any) {
  return {
    type: homeTypes.SHOW_MODAL,
    modalType,
    modalProps
  };
}

export function hideModal() {
  return {
    type: homeTypes.HIDE_MODAL
  };
}

export function fetchProfileRequest() {
  return {
    type: homeTypes.FETCH_PROFILE_REQUEST
  };
}

export function fetchProfileSuccess({ entities }: any) {
  return {
    type: homeTypes.FETCH_PROFILE_SUCCESS,
    entities
  };
}

export function fetchProfileFailure(error: any) {
  return {
    type: homeTypes.FETCH_PROFILE_FAILURE,
    error
  };
}

export function fetchPostRequest(profileId: string, postId: number) {
  return {
    type: homeTypes.FETCH_POST_REQUEST,
    profileId,
    postId
  };
}

export function fetchPostSuccess(
  profileId: string,
  postId: number,
  { result, entities }: any
) {
  return {
    type: homeTypes.FETCH_POST_SUCCESS,
    profileId,
    postId,
    result,
    entities
  };
}

export function fetchPostFailure(
  profileId: string,
  postId: number,
  error: any
) {
  return {
    type: homeTypes.FETCH_POST_FAILURE,
    profileId,
    postId,
    error
  };
}

export function fetchPostsRequest(profileId: string, opts: any) {
  return {
    type: homeTypes.FETCH_POSTS_REQUEST,
    profileId,
    opts
  };
}

export function fetchPostsSuccess(
  profileId: string,
  { entities, result }: any,
  hasNext: boolean
) {
  return {
    type: homeTypes.FETCH_POSTS_SUCCESS,
    profileId,
    entities,
    result,
    hasNext
  };
}

export function fetchPostsFailure(profileId: string, error: any) {
  return {
    type: homeTypes.FETCH_POSTS_FAILURE,
    profileId,
    error
  };
}

export function fetchCommentsRequest(profileId: string, postId: number) {
  return {
    type: homeTypes.FETCH_COMMENTS_REQUEST,
    profileId,
    postId
  };
}

export function fetchCommentsSuccess(
  profildId: string,
  postId: number,
  direction: string,
  hasNext: boolean,
  { result, entities }: any
) {
  return {
    type: homeTypes.FETCH_COMMNETS_SUCCESS,
    profildId,
    postId,
    direction,
    hasNext,
    result,
    entities
  };
}

export function fetchCommentsFailure(
  profileId: string,
  postId: number,
  error: any
) {
  return {
    type: homeTypes.FETCH_COMMNETS_FAILURE,
    profileId,
    postId,
    error
  };
}

export function fetchRealtimeRequest(cid: string) {
  return {
    type: homeTypes.FETCH_REALTIME_REQUEST,
    cid
  };
}

export function fetchRealtimeSuccess(cid: string, { entities, result }: any) {
  return {
    type: homeTypes.FETCH_REALTIME_SUCCESS,
    cid,
    entities,
    result
  };
}

export function fetchRealtimeFailure(cid: string, error: any) {
  return {
    type: homeTypes.FETCH_REALTIME_FAILURE,
    cid,
    error
  };
}
