import React from 'react';
import {Post} from 'models/Post';
import {ADULT_AUTH_VALID_TYPE} from 'state/modules/home/constants';
import get from "lodash/get";

interface Props {
  type: string,
  post: Post
}

const PostAgeCover: React.FC<Props> = ({ type, post}) => {
  const profile = post.author;
  const isFeed = type === 'feed';
  const validType = get(profile, 'authInfo.validType', 'undefined');
  const ageAuthUrl = get(profile.authInfo, 'ageAuthUrl', '');
  const loginPath = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=`;

  const renderCoverFeedView = () => {
    if (validType === ADULT_AUTH_VALID_TYPE.UNAUTHORIZED
      || validType === ADULT_AUTH_VALID_TYPE.UNAUTHORIZED_AGE) {
      return (
        <div className="post_card card_empty">
          <div className="wrap_thumb">
            <span className="ico_plus ico_usercheck"></span>
          </div>
          <strong className="tit_empty">연령 확인이 필요합니다 </strong>
          <p className="desc_empty">20세 이상만 확인할 수 있습니다.<br />본인인증을 진행해주세요. (최초 1회)</p>
          <button type="button" className="btn_gray" onClick={() => { window.location.href = ageAuthUrl; }}>
            인증하기
          </button>
        </div>
      );

    } else if (validType === ADULT_AUTH_VALID_TYPE.LOWER_AGE_LIMIT) {
      return (
        <div className="post_card card_empty">
          <div className="wrap_thumb">
            <span className="ico_plus ico_userstop"></span>
          </div>
          <strong className="tit_empty">연령 제한으로 확인할 수 없습니다 </strong>
          <p className="desc_empty">본인인증 정보 기준<br />20세 이상만 확인할 수 있습니다.</p>
          <button type="button" className="btn_gray" onClick={() => { window.location.href = ageAuthUrl; }}>
            내 정보 확인하기
          </button>
        </div>
      );

    } else { // not found
      const returnUrl = profile.permalink;
      return (
        <div className="post_card card_empty">
          <div className="wrap_thumb">
            <span className="ico_plus ico_user"></span>
          </div>
          <strong className="tit_empty">로그인 후 이용해주세요 </strong>
          <p className="desc_empty">20세 이상 인증이 필요합니다.</p>
          <button type="button" className="btn_gray" onClick={() => { window.location.href = loginPath.concat(returnUrl); }}>
            로그인
          </button>
        </div>
      );
    }
  }

  const renderCoverDetailView = () => {
    if (validType === ADULT_AUTH_VALID_TYPE.UNAUTHORIZED
      || validType === ADULT_AUTH_VALID_TYPE.UNAUTHORIZED_AGE) {
      return (
        <div className="card_empty card_emptydetail">
          <div className="wrap_thumb">
            <span className="ico_plus ico_usercheck"></span>
          </div>
          <strong className="tit_empty">연령 확인이 필요합니다 </strong>
          <p className="desc_empty">20세 이상만 확인할 수 있습니다.<br />본인인증을 진행해주세요. (최초 1회)</p>
          <button type="button" className="btn_gray" onClick={() => { window.location.href = ageAuthUrl; }}>
            인증하기
          </button>
        </div>
      );

    } else if (validType === ADULT_AUTH_VALID_TYPE.LOWER_AGE_LIMIT) {
      return (
        <div className="card_empty card_emptydetail">
          <div className="wrap_thumb">
            <span className="ico_plus ico_userstop"></span>
          </div>
          <strong className="tit_empty">연령 제한으로 확인할 수 없습니다 </strong>
          <p className="desc_empty">본인인증 정보 기준<br />20세 이상만 확인할 수 있습니다.</p>
          <button type="button" className="btn_gray" onClick={() => { window.location.href = ageAuthUrl; }}>
            내 정보 확인하기
          </button>
        </div>
      );

    } else { // not found
      const returnUrl = post.permalink;
      return (
        <div className="card_empty card_emptydetail">
          <div className="wrap_thumb">
            <span className="ico_plus ico_user"></span>
          </div>
          <strong className="tit_empty">로그인 후 이용해주세요 </strong>
          <p className="desc_empty">20세 이상 인증이 필요합니다.</p>
          <button type="button" className="btn_gray" onClick={() => { window.location.href = loginPath.concat(returnUrl); }}>
            로그인
          </button>
        </div>
      );
    }
  }

  return ( isFeed ? renderCoverFeedView() : renderCoverDetailView() );
};

export default PostAgeCover;
