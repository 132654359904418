import { Post } from 'models/Post';
import { useSelector } from 'react-redux';
import React, { useRef, useState, useLayoutEffect } from 'react';
import { SHARE, COMMENT, LIKE } from 'state/modules/home/constants';
import { RootState } from 'state/modules';
import numberFormat from 'utils/numberFormat';
import { isMobile } from 'utils/deviceUtils';

function getScrollYPosition() {
  const target = document.body;
  const position = target.getBoundingClientRect();

  return position.top;
}

interface ScrollCallbackParams {
  previousYPosition: number;
  currentYPosition: number;
}
type ScrollCallback = (params: ScrollCallbackParams) => void;

// TODO: dependencies 를 ReadonlyArray<any> 타입으로 제공해야 함
const useScrollPosition = (effect: ScrollCallback, dependencies: any) => {
  const position = useRef(getScrollYPosition());
  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentPosition = getScrollYPosition();

      effect({
        previousYPosition: position.current,
        currentYPosition: currentPosition
      });
      position.current = currentPosition;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [effect, dependencies]);
};

const isBottom = () => {
  return window.innerHeight + window.scrollY >= document.body.scrollHeight;
};

interface Props {
  post: Post;
  onShowModal: Function;
}

const PostActionBar: React.FC<Props> = ({
  post,
  post: { commentCount, permalink, shareCount, likeCount },
  onShowModal
}) => {
  const [visibility, setVisibility] = useState('off');

  useScrollPosition(({ previousYPosition, currentYPosition }) => {
    const isShow =
      !isBottom() &&
      (currentYPosition >= 0 || currentYPosition > previousYPosition)
        ? 'on'
        : 'off';
    if (isShow !== visibility) {
      setVisibility(isShow);
    }
  }, [visibility]);

  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );
  const mobile = isMobile(useragent);

  return (
    <div className={`post_state ${visibility}`}>
      <strong className="screen_out">좋아요, 댓글, 공유 상태바</strong>
      <button
        type="button"
        className="btn_count"
        onClick={() => onShowModal(LIKE, { post, isPC: !mobile })}
      >
        <span className="ico_plus ico_heart">좋아요</span>
        {!post.blindType || post.blindType === ''
          ? post.likeCount > 0
            ? numberFormat(likeCount)
            : '좋아요'
          : 0}
      </button>
      <button
        type="button"
        className={`btn_count btn_cmt ${post.commentable ? '' : 'off'}`}
        onClick={() => post.commentable && onShowModal(COMMENT, { post, isPC: !mobile })}
      >
        <span className="ico_plus ico_cmt">댓글</span>
        {!post.blindType || post.blindType === ''
          ? post.commentCount > 0
            ? numberFormat(commentCount)
            : '댓글'
          : 0}
      </button>
      <button
        type="button"
        className={`btn_count ${!post.unlisted ? '' : 'off'}`}
        onClick={() =>
          !post.blindType || post.blindType === ''
            ? !post.unlisted && onShowModal(SHARE, { post, permalink })
            : alert('운영정책에 의해 제한된 게시물입니다.')
        }
      >
        <span className="ico_plus ico_share">공유</span>
        {!post.blindType || post.blindType === ''
          ? post.shareCount > 0
            ? numberFormat(shareCount)
            : '공유'
          : 0}
      </button>
    </div>
  );
};

export default PostActionBar;
