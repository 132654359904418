import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import last from 'lodash/last';
import { AGE_AUTH, ADULT_AUTH_VALID_TYPE } from 'state/modules/home/constants';
import { homeSelectors, homeOperations } from 'state/modules/home';
import { showModal } from 'state/modules/home/actions';
import ContentWrapper from 'views/components/ContentWrapper';
import ProfileHeader from 'views/components/ProfileHeader';
import Feed from 'views/components/Feed';
import Tab from 'views/components/Tab';
import Gnb from 'views/components/Gnb';
import { useTiara } from 'utils/tiaraHelper';
import { Profile, TabType } from 'models/Profile';
import { Post } from 'models/Post';
import { Connection } from 'models/Connection';
import { RootState } from 'state/modules';
import { LeverageTab } from 'models/LeverageTemplate';
import HomeTab from 'views/components/HomeTab';
import queryString from 'query-string';
import { isMobile } from 'utils/deviceUtils';
import MobileBridgePage from 'views/pages/bridges/MobileBridgePage';
import DefaultMobileBridgePage from 'views/pages/bridges/DefaultMobileBridgePage';
import OnlyMobileErrorPage from 'views/pages/OnlyMobileErrorPage';
import BottomSheet from './BottomSheet';
import ProfileCompletedLayer from '../components/ProfileCompletedLayer';
import UrlCopyLayer from '../components/UrlCopyLayer';

interface Props {
  profileId: string;
  profile: Profile;
  posts: Post[];
  connections: Connection[];
  hometab: LeverageTab;
  onShowModal: Function;
  fetchProfile: Function;
  fetchPosts: Function;
  error: any;
}

const ProfilePage: React.FC<Props> = ({
  profileId,
  profile,
  posts,
  connections,
  hometab,
  onShowModal,
  fetchProfile,
  fetchPosts,
  error
}) => {
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [copyUrlVisibility, setCopyUrlVisibility] = useState('none');
  const debouncedHandleScroll = debounce(handleScroll, 500);
  const hasNext = get(profile, ['posts', 'hasNext']);
  const [tab, setTab] = useState<TabType>(profile?.homeDefaultTab);
  const params = queryString.parse(window.location.search);
  const completedLayer = params?.completedLayer ?? 'none';
  const changeTab = useCallback(
    name => (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();
      setTab(name);
      window.scrollTo(0, 0);
    },
    [setTab]
  );

  const viewBottomSheet = useCallback(() => {
    setOpenBottomSheet(!openBottomSheet);
  }, [openBottomSheet]);

  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );

  useTiara({
    page: 'profile'
  });

  // 1회만 조회
  useEffect(() => {
    fetchProfile(profileId);
  }, [fetchProfile, profileId]);

  useEffect(() => {
    if (!tab) {
      setTab(profile?.homeDefaultTab);
    }
  }, [profile, tab]);

  const layerCode = (code: string) => {
    setCopyUrlVisibility(code);
  };

  const contents = useMemo(() => {
    const defaultContents = (
      <>
        <Gnb profile={profile} />
        <ContentWrapper accessbility={{ content: '프로필 본문영역' }}>
          <ProfileHeader
            profile={profile}
            connections={connections}
            onShowModal={onShowModal}
            viewBottomSheet={viewBottomSheet}
            layerCode={layerCode}
          />
          <Tab tab={tab} changeTab={changeTab} />
          {tab === TabType.post ? (
            <Feed
              profile={profile}
              posts={posts}
              hasNext={hasNext}
              onShowModal={onShowModal}
              layerCode={layerCode}
            />
          ) : (
            <HomeTab
              profile={profile}
              hometab={hometab}
              changeTab={changeTab}
            />
          )}
        </ContentWrapper>
        <div style={{ display: openBottomSheet ? 'block' : 'none' }}>
          <BottomSheet viewBottomSheet={viewBottomSheet} profile={profile} />
        </div>
        <ProfileCompletedLayer
          completedLayer={completedLayer.toString()}
          profile={profile}
        />
        <UrlCopyLayer completedLayer={copyUrlVisibility} profile={profile} />
      </>
    );

    if (!window.location.search) {
      return defaultContents;
    }
    if (!('url' in params) && !('bridge' in params)) {
      return defaultContents;
    }
    const bridge = params?.bridge ?? '';
    let url = Array.isArray(params.url) ? params.url[0] : params.url;
    url = !url ? '' : url!.trim();
    if (isMobile(useragent)) {
      if (bridge === 'kakaoview') {
        return (
          <DefaultMobileBridgePage
            scheme={`plusfriend/home/${profile?.id}?default_tab=creatorCenter`}
          />
        );
      }
      return <MobileBridgePage profile={profile} url={url} />;
    } else {
      return <OnlyMobileErrorPage />;
    }
  }, [
    profile,
    connections,
    onShowModal,
    viewBottomSheet,
    tab,
    changeTab,
    posts,
    hasNext,
    hometab,
    openBottomSheet,
    completedLayer,
    copyUrlVisibility,
    params,
    useragent
  ]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', debouncedHandleScroll);
    }
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  });

  useEffect(() => {
    if (!profile) {
      return;
    }

    const isForAdult = profile.isAdult;
    const validType = get(
      profile,
      'authInfo.validType',
      ADULT_AUTH_VALID_TYPE.UNAUTHORIZED
    );

    if (isForAdult && validType !== ADULT_AUTH_VALID_TYPE.VERIFIED) {
      onShowModal(AGE_AUTH, { profile });
    }
  }, [profile, onShowModal, profileId]);

  if (!profile) {
    if (error?.response?.status === 404) {
      window.location.href = '/';
    }
    return <></>;
  }

  function handleScroll() {
    const scrollNode = document.scrollingElement
      ? (document.scrollingElement as HTMLElement)
      : document.documentElement;
    const scrollHeight = scrollNode.scrollHeight;
    const scrollTop = scrollNode.scrollTop;
    const offsetHeight = scrollNode.offsetHeight;
    if (
      tab === TabType.post &&
      hasNext &&
      scrollHeight - 2 * offsetHeight < scrollTop
    ) {
      fetchPosts(profileId, { since: last(posts)?.sort ?? undefined });
    }
  }

  return <>{contents}</>;
};

function mapStatetoProps(state: RootState, props: any) {
  const {
    home: { error }
  } = state;

  const {
    match: {
      params: { encodedProfileId: profileId }
    }
  } = props;

  return {
    error: error.error,
    profileId,
    profile: homeSelectors.getProfile(state, props),
    posts: homeSelectors.getProfilePosts(state, props),
    connections: homeSelectors.getProfileConnections(state, props),
    hometab: homeSelectors.getProfileHomeTab(state, props)
  };
}

export default connect(mapStatetoProps, dispatch =>
  bindActionCreators(
    {
      onShowModal: showModal,
      fetchProfile: homeOperations.fetchProfile,
      fetchPosts: homeOperations.fetchPosts
    },
    dispatch
  )
)(ProfilePage);
