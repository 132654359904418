import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Video } from 'models/Medium';

const RATIO = 16 / 9;

interface Props {
  video: Video;
}
const VideoPlayer: React.FC<Props> = ({ video }) => {
  const [containerStyle, setContainerStyle] = useState({
    width: 520,
    height: 520 / RATIO
  });
  const container = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!container.current) return;

    const calculateSize = () => {
      if (!container.current) return;
      const parentContainer = container.current.parentNode as HTMLElement;
      if (!parentContainer) return;
      const parentContainerRect = parentContainer.getBoundingClientRect();
      setContainerStyle({
        width: parentContainerRect.width,
        height: parentContainerRect.width / RATIO
      });
    };

    calculateSize();
    window.addEventListener('resize', calculateSize);

    return () => window.removeEventListener('resize', calculateSize);
  }, [container]);

  const playPath =
    (includes(get(video, 'playUrl'), 'sandbox')
      ? 'https://sandbox-tv.kakao.com'
      : 'https://tv.kakao.com') + '/embed/player';
  const videoSrc = `${playPath}/${
    !get(video, 'clipLinkId') || get(video, 'clipLinkId') === 0
      ? 'livelink/' + get(video, 'liveLinkId')
      : 'cliplink/' + get(video, 'clipLinkId')
  }?service=kakao_talkplus&play_loc=kakao_talkplus&autoplay=true`;
  return (
    <div className="card_default card_vod">
      <Link
        to="#"
        style={{ height: 'auto' }}
        ref={container}
        onClick={e => e.preventDefault()}
      >
        <div id="videoPlayer" className="wrap_thumb" style={containerStyle}>
          <iframe
            title={video.name}
            width="100%"
            height={360 * RATIO}
            style={containerStyle}
            src={videoSrc}
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            allow="autoplay; fullscreen"
          />
        </div>
      </Link>
    </div>
  );
};

export default VideoPlayer;
