export default {
  SHOW_MODAL: 'ROCKET-WEB/HOME/SHOW_MODAL',
  HIDE_MODAL: 'ROCKET-WEB/HOME/HIDE_MODAL',
  FETCH_PROFILE_REQUEST: 'ROCKET-WEB/HOME/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'ROCKET-WEB/HOME/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE: 'ROCKET-WEB/HOME/FETCH_PROFILE_FAILURE',
  FETCH_POST_REQUEST: 'ROCKET-WEB/HOME/FETCH_POST_REQUEST',
  FETCH_POST_SUCCESS: 'ROCKET-WEB/HOME/FETCH_POST_SUCCESS',
  FETCH_POST_FAILURE: 'ROCKET-WEB/HOME/FETCH_POST_FAILURE',
  FETCH_POSTS_REQUEST: 'ROCKET-WEB/HOME/FETCH_POSTS_REQUEST',
  FETCH_POSTS_SUCCESS: 'ROCKET-WEB/HOME/FETCH_POSTS_SUCCESS',
  FETCH_POSTS_FAILURE: 'ROCKET-WEB/HOME/FETCH_POSTS_FAILURE',
  FETCH_COMMENTS_REQUEST: 'FETCH_COMMENT_REQUEST',
  FETCH_COMMNETS_SUCCESS: 'FETCH_COMMNETS_SUCCESS',
  FETCH_COMMNETS_FAILURE: 'FETCH_COMMNETS_FAILURE',
  FETCH_REALTIME_REQUEST: 'FETCH_REALTIME_REQUEST',
  FETCH_REALTIME_SUCCESS: 'FETCH_REALTIME_SUCCESS',
  FETCH_REALTIME_FAILURE: 'FETCH_REALTIME_FAILURE'
};
