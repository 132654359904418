import React from 'react';
import ImageHolder from 'views/components/ImageHolder';
import { IMAGE_TYPE } from 'state/modules/home/constants';
import { Image } from 'models/Medium';
import { isEmpty } from 'lodash';

interface Props {
  image?: Image;
  imageType: string;
}
const LinkImage: React.FC<Props> = ({ image, imageType }) => {
  if (isEmpty(image) ) {
    return null;
  }
  if (imageType === IMAGE_TYPE.big) {
    return (
      <div className="wrap_thumb">
        <ImageHolder image={image!} />
      </div>
    );
  } else if (imageType === IMAGE_TYPE.small) {
    return (
      <div className="wrap_thumb">
        <ImageHolder image={image!} />
      </div>
    );
  }

  return null;
};

export default LinkImage;
