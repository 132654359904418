import React, { useRef, useState, useEffect, CSSProperties } from 'react';
import get from 'lodash/get';
import getImageUrl from 'utils/getImageUrl';
import { isGif } from 'utils/imageUtils';
import { Image } from 'models/Medium';

interface Props {
  image: Image;
  viewType?: 'vertical' | string;
  cardWidth?: number;
  cardHeight?: number;
  maxWidth?: number;
  noFrame?: boolean;
  noGifBlur?: boolean;
}
const CardImageHolder: React.FC<Props> = ({
  image,
  viewType,
  cardWidth,
  cardHeight,
  maxWidth,
  noFrame,
  noGifBlur
}) => {
  const [containerStyle, setContainerStyle] = useState<CSSProperties>({
    width: 520,
    height: 520
  });
  const container = useRef<HTMLDivElement>(null);
  const ratio = get(image, 'width') / get(image, 'height');

  useEffect(() => {
    const calculateImageSize = () => {
      if (!container.current) return;
      const parentContainer = container.current.parentNode as HTMLElement;
      if (!parentContainer) return;
      const parentRect = parentContainer.getBoundingClientRect();
      if (isGif(image)) {
        setContainerStyle({
          width: cardWidth ? cardWidth : parentRect.width,
          height: cardHeight ? cardHeight : parentRect.height
        });
      } else {
        setContainerStyle({
          width: cardWidth ? cardWidth : parentRect.width,
          height: viewType === 'vertical' ? 'auto' : parentRect.width
        });
      }
    };

    calculateImageSize();
    window.addEventListener('resize', calculateImageSize);

    return () => window.removeEventListener('resize', calculateImageSize);
  }, [image, viewType, cardWidth, cardHeight]);

  return (
    <div
      style={{
        width: containerStyle.width,
        height: cardHeight ? cardHeight : containerStyle.height,
        position: 'relative',
        maxHeight: Number(containerStyle.width) * 4,
        maxWidth: maxWidth,
        overflow: 'hidden',
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: get(image, 'avg'),
        zIndex: -10
      }}
      ref={container}
    >
      {isGif(image) && !noGifBlur ? (
        <div>
          <span
            className="bg_blur"
            style={{
              background: `url(${get(image, 'largeUrl')}) no-repeat`,
              position: 'absolute',
              width: '100%', // 520,
              height: '100%', // 520,
              backgroundSize: '100% 100%',
              filter: 'blur(10px)',
              WebkitFilter: 'blur(10px)',
              MozFilter: 'blur(10px)',
              OFilter: 'blur(10px)',
              top: '-15px',
              right: '-15px',
              bottom: '-15px',
              left: '-15px',
              zIndex: -10
            } as any}
          ></span>
          <span
            className="frame_dimmed"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgb(0,0,0)',
              opacity: 0.5,
              zIndex: -9,
              filter: 'alpha(opacity=50)'
            }}
          ></span>
        </div>
      ) : null}
      <img
        src={getImageUrl(get(image, 'largeUrl') || get(image, 'url'))}
        className="img_thumb"
        alt=""
        style={
          viewType !== 'vertical'
            ? {
                position: 'absolute',
                margin: 'auto',
                width: ratio > 1.0 ? 'auto' : '100%',
                height: ratio > 1.0 ? '100%' : 'auto',

                minWidth: '100%',
                minHeight: '100%',

                left: '-1000%',
                right: '-1000%',
                top: '-1000%',
                bottom: '-1000%'
              }
            : {
                width: '100%',
                height: 'auto'
              }
        }
      />
      {isGif(image) && noGifBlur && <span className="txt_gif">GIF</span>}
      {!noFrame && <span className="frame_g"></span>}
    </div>
  );
};

export default CardImageHolder;
