import React from 'react';

interface Props {
  hasNext: boolean;
}
const FeedFooter: React.FC<Props> = ({ hasNext }) => {
  return hasNext ? (
    <div
      className="post_load"
      style={{ backgroundColor: 'rgb(239, 239, 239)' }}
    >
      <span className="ico_load">로딩중입니다.</span>
    </div>
  ) : (
    <div className="post_copyright">
      <span className="screen_out">Copyright</span>
      <span className="ico_plus">카카오톡 채널</span>
    </div>
  );
};

export default FeedFooter;
