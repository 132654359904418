import React from 'react';
import { Profile } from '../../../models/Profile';
interface Props {
  completedLayer: string;
  profile: Profile;
}

const UrlCopyLayer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  completedLayer,
  profile
}) => (
  <>
    {completedLayer === 'copyurl' && (
      <div>
        <div id="dimmedLayer" className="dimmed_layer"></div>
        <div
          className="plus_layer channel_layer"
          style={{ marginTop: '-120px' }}
        >
          <div id="completedLayer" className="layer_body">
            <p className="txt_layer">
              <br />
              URL을 복사하였습니다.
            </p>
            <div className="app_btn">
              <button
                type="button"
                className="btn_g"
                onClick={() => {
                  const layer = window.document.getElementById(
                    'completedLayer'
                  );
                  const dimmedLayer = window.document.getElementById(
                    'dimmedLayer'
                  );
                  if (!!layer) {
                    layer.style.display = 'none';
                  }
                  if (!!dimmedLayer) {
                    dimmedLayer.style.display = 'none';
                  }
                  // param 제거
                  window.location.href =
                    window.location.protocol +
                    '//' +
                    window.location.hostname +
                    window.location.pathname;
                }}
              >
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

export default UrlCopyLayer;
