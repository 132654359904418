import React from 'react';
import get from 'lodash/get';
import BaseModal from 'views/components/Modal/BaseModal';
import { ADULT_AUTH_VALID_TYPE } from 'state/modules/home/constants';
import { Profile } from 'models/Profile';

interface Props {
  profile: Profile;
}
const AgeAuthAlertModal: React.FC<Props> = ({ profile }) => {
  const validType = get(profile, 'validType', 'undefined');
  const isUnder19 = validType === ADULT_AUTH_VALID_TYPE.LOWER_AGE_LIMIT;
  const isNotFound =
    validType === 'undefined' || validType === ADULT_AUTH_VALID_TYPE.NOT_FOUND;
  const isUnauthorized =
    validType === ADULT_AUTH_VALID_TYPE.UNAUTHORIZED ||
    validType === ADULT_AUTH_VALID_TYPE.UNAUTHORIZED_AGE;

  const getModalMessage = () => {
    const profileName = get(profile, 'name');
    if (isUnder19) {
      return (
        <>
          해당 채널 {profileName}(은)는 청소년에 유해한 정보/컨텐츠 등이
          포함되어 있을 수 있어 정보통신망 이용촉진및 정보보호 등에 관한 법률 및
          청소년 보호법에 따라 19세미만의 청소년이 이용할 수 없습니다.
        </>
      );
    }

    if (isNotFound) {
      return (
        <>
          해당 채널 {profileName}(은)는 청소년에 유해한 정보/컨텐츠 등이
          포함되어 있을 수 있어 정보통신망 이용촉진및 정보보호 등에 관한 법률 및
          청소년 보호법에 따라 19세미만의 청소년이 이용할 수 없습니다.
          <br />
          로그인 후 카카오 계정의 본인 확인을 해주세요.
        </>
      );
    }

    if (isUnauthorized) {
      return (
        <>
          해당 채널 {profileName}(은)는 청소년에 유해한 정보/컨텐츠 등이
          포함되어 있을 수 있어 정보통신망 이용촉진및 정보보호 등에 관한 법률 및
          청소년 보호법에 따라 19세미만의 청소년이 이용할 수 없습니다.
          <br />
          카카오 계정의 본인 확인을 해주세요.
        </>
      );
    }
  };

  const goToLogin = () => {
    const returnUrl = profile.permalink;
    window.location.href = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=${returnUrl}`;
  };

  const goToLogout = () => {
    const returnUrl = profile.permalink;
    const loginPage = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=${returnUrl}`;
    window.location.href = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/logout?continue=${loginPage}`;
  };

  const goToAgeAuth = () => {
    const ageAuthUrl = get(profile, 'authInfo.ageAuthUrl');
    window.location.href = ageAuthUrl;
  };

  return (
    <BaseModal contentLabel="댓글">
      {{
        renderTitle: () => (
          <div className="adult_layer">
            <strong className="tit_layer">
              <img
                src="http://k.kakaocdn.net/dn/cIPppK/btqrEUNCeMl/ck0KHBxLilu60CEWbXTXEK/img_xl.jpg"
                className="info_cate"
                width="33"
                height="19"
                alt="성인"
              />
              성인인증이 필요합니다.
            </strong>
          </div>
        ),
        renderContent: () => (
          <div className="adult_layer">
            <p className="txt_layer">{getModalMessage()}</p>
            <div className="app_btn">
              <button
                type="button"
                className="btn_g"
                onClick={() =>
                  isUnder19
                    ? goToLogout()
                    : isNotFound
                    ? goToLogin()
                    : goToAgeAuth()
                }
              >
                <span className="inner_txt">
                  {isUnder19
                    ? '다른 계정으로 로그인'
                    : isNotFound
                    ? '로그인 하러 가기'
                    : '본인인증 하러 가기'}
                </span>
              </button>
            </div>
          </div>
        ),
        isButtonInside: true
      }}
    </BaseModal>
  );
};

export default AgeAuthAlertModal;
