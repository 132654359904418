import React, { Fragment, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  ADD_FRIEND,
  BLOCK_FRIEND,
  PROFILE_IMAGE
} from 'state/modules/home/constants';
import ProfileImage from 'views/components/ProfileImage';
import numberWithCommas from 'utils/numberFormat';
import ProfileVerificationBadge from 'views/components/ProfileVerificationBadge';
import { Profile } from 'models/Profile';
import { Connection } from 'models/Connection';

interface Props {
  profile: Profile;
  onShowModal: Function;
  connections: Array<Connection>;
  device?: object;
  viewBottomSheet: Function;
  layerCode: Function;
}

const ProfileHeader: React.FC<Props> = ({
  profile,
  onShowModal,
  viewBottomSheet,
  layerCode
}) => {
  const {
    profileImage,
    statusMessage,
    friendCount,
    verificationType,
    isChatBot,
    isOfficial
  } = profile;

  const copyUrl = () => {
    const hiddenUrl = document.createElement('textarea');
    hiddenUrl.value = window.location.href;
    document.body.appendChild(hiddenUrl);
    hiddenUrl.select();
    document.execCommand('copy');
    document.body.removeChild(hiddenUrl);
    setShowAdditionalBtn(!showAdditionalBtn);
    layerCode('copyurl');
  };

  const clickProfileImage = useCallback(
    ev => {
      ev.preventDefault();
      onShowModal(PROFILE_IMAGE, { profileImage });
    },
    [profileImage, onShowModal]
  );

  const getFormattedStatusMessage = () => {
    if (!statusMessage) {
      return null;
    }

    let previewStatusMessage = statusMessage;
    if (previewStatusMessage.length > 55) {
      previewStatusMessage = previewStatusMessage
        .substring(0, 55)
        .concat('...');
    }

    return previewStatusMessage.split('\n').map((item, index) => (
      <Fragment key={index}>
        {item}
        <br />
      </Fragment>
    ));
  };

  const openSpamReportLink = () => {
    const loginRedirectUrl = spamReportUrl;
    const logoutRedirectUrl = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=`;
    setShowAdditionalBtn(!showAdditionalBtn);
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/web/profiles/${profile?.profileId}/logincheck?logoutRedirectUrl=${logoutRedirectUrl}&loginRedirectUrl=${loginRedirectUrl}`,
      '_blank'
    );
  };

  const [showAdditionalBtn, setShowAdditionalBtn] = useState(false);
  const currentPage = window.location.href;
  const link = `/${profile?.profileId}/friend_direct`;
  const blockFriendlink = `/${profile?.profileId}/friend_block?returl=${currentPage}%3FcompletedLayer=blockfriend`;
  const spamReportUrl = window.location.href + '/spam_report';

  return (
    <div>
      <h2 id="kakaoBody" className="screen_out">
        프로필 본문영역
      </h2>
      <div>
        <strong className="screen_out">메인 프로필</strong>
        <div className="view_profile">
          <div className="wrap_profile">
            <Link to="#none" className="link_thumb" onClick={clickProfileImage}>
              <ProfileImage image={profileImage} sizeType="small" />
              <span className="screen_out">프로필 사진 보기</span>
            </Link>
            <div className="area_info">
              <div className="inner_info">
                <div className="box_info">
                  <strong className="tit_info">{profile.name}</strong>
                  <ProfileVerificationBadge
                    type={verificationType}
                    isOfficial={isOfficial}
                    viewBottomSheet={viewBottomSheet}
                  />
                </div>
                <span className="txt_friends">
                  친구 {numberWithCommas(friendCount)}
                </span>
              </div>
            </div>
            <div className="area_util">
              <a
                href={`/${profile?.profileId}/chat`}
                target="_blank"
                className="chatting"
                rel="noopener noreferrer"
                data-tiara-layer="chatting"
              >
                <button type="button" className="btn_util">
                  <span
                    className={classnames('ico_plus', {
                      ico_chatting: !isChatBot,
                      ico_chatbot: isChatBot
                    })}
                  >
                    채팅방
                  </span>
                </button>
              </a>
              {profile.isFriend && (
                <button
                  type="button"
                  className="btn_util"
                  onClick={() =>
                    onShowModal(BLOCK_FRIEND, { profile, blockFriendlink })
                  }
                >
                  <span className="ico_plus ico_add_after">채널차단</span>
                </button>
              )}
              {!profile.isFriend && (
                <button
                  type="button"
                  className="btn_util"
                  onClick={() => onShowModal(ADD_FRIEND, { profile, link })}
                >
                  <span className="ico_plus ico_add_before">채널추가</span>
                </button>
              )}
            </div>
            <p className="txt_info">{getFormattedStatusMessage()}</p>
            <div className="area_nav">
              <button
                type="button"
                className="btn_nav"
                onClick={() => setShowAdditionalBtn(!showAdditionalBtn)}
              >
                <span className="ico_plus ico_nav_more">더보기</span>
              </button>
              <div
                className="layer_opt"
                style={{ display: !!showAdditionalBtn ? '' : 'none' }}
              >
                <ul className="list_opt">
                  <li>
                    <button
                      type="button"
                      className="btn_opt"
                      onClick={() => openSpamReportLink()}
                    >
                      <span className="txt_opt">신고하기</span>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="btn_opt"
                      onClick={() => copyUrl()}
                    >
                      <span className="txt_opt">URL 복사하기</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <input
              type="text"
              id="url_text"
              value="https://plitche.github.io"
              style={{ display: 'none' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
