import React from 'react';
import { PUBLIC_INSTITUTION, BUSINESS } from 'state/modules/home/constants';
import VerificationBadge from './VerificationBadge';

interface Props {
  type: string;
  isOfficial: boolean;
  viewBottomSheet: Function;
}
const ProfileVerificationBadge: React.FC<Props> = ({ type, isOfficial, viewBottomSheet }) => {
  if (type === BUSINESS || isOfficial || type === PUBLIC_INSTITUTION) {
    return <VerificationBadge viewBottomSheet={viewBottomSheet}/>;
  }
  return null;
};

export default ProfileVerificationBadge;
