import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Gnb from 'views/components/Gnb';
import { getOsName } from 'utils/deviceUtils';
import { androidInstallUrl, iosInstallUrl } from 'state/modules/home/constants';
import openWithApp from 'utils/openWithApp';
import { RootState } from 'state/modules';
import { useSelector } from 'react-redux';

interface Props {
  scheme: string;
}

const DefaultMobileBridgePage: React.FC<Props> = ({ scheme }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );

  const deviceOS = useMemo(() => {
    return getOsName(useragent);
  }, [useragent]);

  const onTalkExec = useCallback(() => {
    const installUrl =
      deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl;
    openWithApp(
      deviceOS,
      deviceOS === 'iOS'
        ? process.env.REACT_APP_PF_CUSTOM_SCHEME || 'kakaoplus'
        : 'kakaoplus',
      scheme,
      'com.kakao.talk',
      installUrl
    );
  }, [deviceOS, scheme]);

  useEffect(() => {
    onTalkExec();
    window.addEventListener('resize', () => {
      setWindowHeight(window.innerHeight);
    });

    return window.removeEventListener('resize', () => {});
  }, [onTalkExec, useragent]);

  return (
    <>
      <Gnb />
      <div
        id="kakaoContent"
        className="cont_bridge"
        role="main"
        style={{ height: windowHeight }}
      >
        <div className="inner_bridge">
          <span className="ico_bridge ico_type3">채팅 시작</span>
          <strong className="tit_bridge">카카오톡에서 만나요</strong>
          <p className="desc_bridge">
            해당 채널 정보는 카카오톡 최신 버전에서
            <br /> 이용하실 수 있습니다.
          </p>
          <a
            href="#none"
            className="link_bridge link_type1"
            onClick={() => onTalkExec()}
          >
            카카오톡에서 보기
          </a>
          <a
            href={deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl}
            className="link_bridge link_type2"
          >
            카카오톡 설치하기
          </a>
        </div>
      </div>
    </>
  );
};

export default DefaultMobileBridgePage;
