import React from 'react';
import { Route, Switch } from 'react-router';
import ScrollToTop from 'views/components/ScrollToTop';
import ProfilePage from 'views/pages/ProfilePage';
import PostArchivePage from 'views/pages/PostArchivePage';
import PostPage from 'views/pages/PostPage';
import ErrorPage from 'views/pages/ErrorPage';
import CouponPage from 'views/pages/CouponPage';
import BridgePage from 'views/pages/BridgePage';
import SDKErrorPage from './views/pages/SDKErrorPage';
import CouponBookPage from 'views/pages/CouponBookPage';

export default function createRoutes() {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path="/:encodedProfileId(_[a-zA-Z]+)/bridge"
          component={BridgePage}
        />
        <Route
          exact
          path="/:encodedProfileId(_[a-zA-Z]+)/:postId(\d+)"
          component={PostPage}
        />
        <Route
          exact
          path="/:encodedProfileId(_[a-zA-Z]+)"
          component={ProfilePage}
        />
        <Route
          exact
          path="/:encodedProfileId(_[a-zA-Z]+)/posts"
          component={PostArchivePage}
        />
        <Route
          exact
          path="/:encodedProfileId(_[a-zA-Z]+)/coupons/:couponId"
          component={CouponPage}
        />
        <Route
          exact
          path="/:encodedProfileId(_[a-zA-Z]+)/couponbook"
          component={CouponBookPage}
        />
        <Route
          exact
          path="/:encodedProfileId(_[a-zA-Z]+)/sdk_error"
          component={SDKErrorPage}
        />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </>
  );
}
