import React from 'react';
import ModalRoot from 'views/containers/ModalRoot';
import { BottomToaster } from '../components/Toast/BottomToast';

const HomeLayout: React.FC = ({ children }) => (
  <>
    <div id="kakaoIndex">
      <a href="#kakaoBody">본문 바로가기</a>
      <a href="#kakaoGnb">메뉴 바로가기</a>
    </div>
    <div id="kakaoWrap" className="wrap_plus">
      {children}
      <ModalRoot />
      <BottomToaster />
    </div>
  </>
);

export default HomeLayout;
