/**
 * 신고조치 되어서 제재된 포스트인지 여부
 * 프로필이 제재되어도 blindType에 값이 들어온다 (B)
 * @param {String} blindType 제재구분
 */
export function isBlindPost(blindType = '') {
  return !!blindType.trim();
}

export function isBlindContentType(blindType?: string) {
  return 'C' === blindType;
}

export function isBlindTemporaryType(blindType?: string) {
  return 'T' === blindType;
}

/**
 * 포스트 상세의 내용만 제한하는 경우를 구분하기 위해 사용
 * @param {String} blindType 제재구분. C: 본문, T: 임시
 */
export function isBlindPostContentOnly(blindType?: string) {
  return isBlindContentType(blindType) || isBlindTemporaryType(blindType);
}
