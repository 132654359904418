import { Image } from 'models/Medium';
import React from 'react';
import { getSquircleThumbnailUrl } from 'utils/imageUtils';
import get from 'lodash/get';

interface Props {
  image: Image;
  sizeType?: 'default' | 'small';
}
const ProfileImage: React.FC<Props> = ({ image, sizeType = 'small' }) => {
  const imageUrl = get(image, `${sizeType}Url`);
  const isVertical = image.height > image.width;

  return (
    <img
      src={getSquircleThumbnailUrl(imageUrl)}
      className="img_thumb"
      style={{
        width: isVertical ? '100%' : 'auto',
        height: isVertical ? 'auto' : '100%',
        position: 'absolute',
        left: '-100%',
        right: '-100%',
        top: '-100%',
        bottom: '-100%',
        margin: 'auto',
        minHeight: '100%',
        minWidth: '100%'
      }}
      alt=""
    />
  );
}

export default ProfileImage;
