import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { isEmpty } from 'lodash';

export interface TiaraTrackPageParam {
  profileId: string | undefined;
  actionName: string;
  page: string;
  pageMeta?: TiaraPageMetaParam;
  section?: string;
}

export interface TiaraTrackEventParam {
  actionName: string;
  page: string;
  pageMeta?: TiaraPageMetaParam;
  click?: TiaraClickParam;
  section?: string;
}

export interface TiaraPageMetaParam {
  id: string | undefined;
}

export interface TiaraClickParam {
  layer1: string;
  layer2?: string;
  layer3?: string;
  ordNum?: number;
  copy?: string | undefined;
  click_url?: string | undefined;
}

const trackPage = ({
  profileId,
  actionName,
  page,
  section
}: TiaraTrackPageParam) => {
  try {
    const tiaraInstance = window.TiaraTracker?.getInstance();
    if (tiaraInstance) {
      if (section) {
        tiaraInstance.setSection(section);
      }
      if (
        profileId &&
        'pageMeta' in tiaraInstance &&
        typeof tiaraInstance.pageMeta === 'function'
      ) {
        tiaraInstance.pageMeta({ id: profileId });
      }
      tiaraInstance
        .trackPage(actionName)
        .setPage(page)
        .track();
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const useTiaraPage = ({
  profileId,
  actionName,
  page,
  section
}: TiaraTrackPageParam) => {
  useEffect(() => {
    if (profileId) {
      trackPage({ profileId, actionName, page, section });
    }
  }, [profileId, actionName, page, section]);
};

export const TiaraTrackUtils = {
  trackClick: (
    { actionName, page, pageMeta, click, section }: TiaraTrackEventParam,
    event?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | MouseEvent
  ) => {
    try {
      const tiaraInstance = window.TiaraTracker?.getInstance();
      if (tiaraInstance) {
        if (section) {
          tiaraInstance.setSection(section);
        }
        if (
          pageMeta &&
          'pageMeta' in tiaraInstance &&
          typeof tiaraInstance.pageMeta === 'function'
        ) {
          tiaraInstance.pageMeta(pageMeta);
        }
        if (
          click &&
          'click' in tiaraInstance &&
          typeof tiaraInstance.click === 'function' &&
          window.TiaraTracker &&
          'getClickInfo' in window.TiaraTracker &&
          typeof window.TiaraTracker.getClickInfo === 'function'
        ) {
          const clickInfo = window.TiaraTracker.getClickInfo(event);
          tiaraInstance.click({
            posx: clickInfo.click?.posx,
            posy: clickInfo.click?.posy,
            copy: clickInfo.click?.copy,
            click_url: isEmpty(clickInfo.click?.click_url)
              ? undefined
              : clickInfo.click?.click_url,
            ...click
          });
        }
        tiaraInstance
          .setPage(page)
          .trackEvent(actionName)
          .setUrl(window.location.href)
          .track();
      }
    } catch (error) {
      Sentry.setExtras({ page, actionName });
      Sentry.captureException(error);
    }
  }
};
