import { Post } from 'models/Post';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/modules';
import BaseModal from 'views/components/Modal/BaseModal';
import { getOsName } from 'utils/deviceUtils';
import 'styles/modal.css';

interface Props {
  post: Post;
  onHideModal: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  isPC: boolean;
}
const CommentModal: React.FC<Props> = ({ onHideModal, post, isPC = false }) => {
  const useragent = useSelector<RootState, UAParser.IResult>(
    state => state.home.useragent.result
  );
  const osName = getOsName(useragent);
  const schemeHost = `plusfriend/post/${post.author.id}/${post.id}`;
  const scheme =
    osName === 'Android'
      ? `kakaoplus://${schemeHost}`
      : `${process.env.REACT_APP_PF_CUSTOM_SCHEME}://${schemeHost}`;

  return (
    <BaseModal contentLabel="댓글" onRequestClose={onHideModal}>
      {{
        renderTitle: () => (
          <span>
            댓글 작성은 카카오톡
            <br />
            모바일앱에서 가능합니다.
          </span>
        ),
        renderContent: () => {
          if (isPC) {
            return (
              <div className="app_btn">
                <button
                  type="button"
                  className="btn_g btn_g2"
                  onClick={onHideModal}
                >
                  <span className="inner_txt">닫기</span>
                </button>
              </div>
            );
          }
          return (
            <div className="app_btn">
              <button
                type="button"
                className="btn_g"
                style={{ margin: '0 3px' }}
                onClick={onHideModal}
              >
                <span className="inner_txt adjust_margin">닫기</span>
              </button>
              <button
                type="button"
                className="btn_g btn_add"
                style={{ margin: '0 3px' }}
                onClick={() => window.open(scheme)}
              >
                <span className="inner_txt adjust_margin">카카오톡앱 열기</span>
              </button>
            </div>
          );
        },
        isButtonInside: true
      }}
    </BaseModal>
  );
};

export default CommentModal;
