import React from 'react';
import { Profile } from '../../../models/Profile';
import AddFriendDoneLayer from '../AddFriendDoneLayer';
import BlockFriendLayer from '../BlockFriendLayer';
interface Props {
  completedLayer: string;
  profile: Profile;
}

const ProfileCompletedLayer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  completedLayer,
  profile
}) => (
  <>
    {completedLayer === 'blockfriend' && <BlockFriendLayer profile={profile} />}
    {completedLayer === 'addfrienddone' && (
      <AddFriendDoneLayer profile={profile} />
    )}
  </>
);

export default ProfileCompletedLayer;
