export default function timeAgoInWords(timeAgo: Date) {
  const now = new Date();
  const diff = now.getTime() - timeAgo.getTime();

  if (diff < (60 * 1000)) {
    return '방금';
  }

  if (diff < (60 * 60 * 1000)) {
    return `${Math.floor(diff / (60 * 1000))}분 전`;
  }

  if (diff < (24 * 60 * 60 * 1000)) {
    return `${Math.floor(diff / (60 * 60 * 1000))}시간 전`;
  }

  const year = timeAgo.getFullYear();
  const month = timeAgo.getMonth() + 1;
  const date = timeAgo.getDate();
  const am = timeAgo.getHours() < 12 || timeAgo.getHours() === 24 ? '오전' : '오후';
  const hour = am === '오전' ? timeAgo.getHours() % 12 : timeAgo.getHours() % 12 || 12;
  const hourTwoDigit = hour < 10 ? '0' + hour : hour;
  const minTwoDigit = timeAgo.getMinutes() < 10 ? '0' + timeAgo.getMinutes() : timeAgo.getMinutes();

  if (year === now.getFullYear()) {
    return `${month}월 ${date}일 ${am} ${hourTwoDigit}:${minTwoDigit}`;
  }

  return `${year}년 ${month}월 ${date}일 ${am} ${hourTwoDigit}:${minTwoDigit}`;
}
