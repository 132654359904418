import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import numberWithCommas from 'utils/numberFormat';
import { SHARE, COMMENT, LIKE } from 'state/modules/home/constants';
import { Post } from 'models/Post';
import { RootState } from 'state/modules';
import { isMobile } from 'utils/deviceUtils';
import { TiaraTrackUtils } from '../../../utils/tiaraNewHelper';

interface Props {
  post: Post;
  onShowModal: Function;
  isFeed: boolean;
}

const PostCountBar: React.FC<Props> = ({ post, onShowModal, isFeed }) => {
  const {
    author,
    permalink,
    commentable,
    unlisted,
    likeCount,
    commentCount,
    shareCount
  } = post;
  const isAdultChannel = get(author, 'isAdult');
  const isAdultPost = post.adultOnly;
  const isBlinded = get(post, 'blindType', false);

  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );
  const mobile = isMobile(useragent);

  return (
    <div className="post_cmt">
      <div className="cmt_util">
        <button
          type="button"
          className="btn_count"
          onClick={() => {
            onShowModal(LIKE, { post, isPC: !mobile });
            TiaraTrackUtils.trackClick({
              actionName: '좋아요_클릭',
              page: isFeed ? 'post_archive' : 'post',
              pageMeta: { id: post?.author?.profileId },
              click: {
                layer1: 'post_action',
                layer2: 'like'
              }
            });
          }}
        >
          <span className="ico_plus ico_heart">좋아요</span>
          {(!post.blindType || post.blindType === '') && likeCount > 0
            ? numberWithCommas(likeCount)
            : 0}
        </button>
        {commentable && (
          <button
            type="button"
            className="btn_count"
            onClick={() => {
              onShowModal(COMMENT, { post, isPC: !mobile });
              TiaraTrackUtils.trackClick({
                actionName: '댓글_클릭',
                page: isFeed ? 'post_archive' : 'post',
                pageMeta: { id: post?.author?.profileId },
                click: {
                  layer1: 'post_action',
                  layer2: 'reply'
                }
              });
            }}
          >
            <span className="ico_plus ico_cmt">댓글</span>
            {(!post.blindType || post.blindType === '') && commentCount > 0
              ? numberWithCommas(commentCount)
              : 0}
          </button>
        )}
        {!unlisted && !isAdultChannel && (
          <button
            type="button"
            className="btn_count btn_share btn_share2"
            onClick={() => {
              TiaraTrackUtils.trackClick({
                actionName: '공유_클릭',
                page: isFeed ? 'post_archive' : 'post',
                pageMeta: { id: post?.author?.profileId },
                click: {
                  layer1: 'post_action',
                  layer2: 'share'
                }
              });
              isBlinded
                ? alert('운영정책에 의해 제한된 게시물입니다.')
                : isAdultPost
                ? alert('공유할 수 없는 소식입니다.')
                : onShowModal(SHARE, { post, permalink });
            }}
          >
            <span className="inner_txt">
              <span className="ico_plus ico_share" />
              공유{' '}
              {isBlinded ? 0 : shareCount > 0 && numberWithCommas(shareCount)}
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default PostCountBar;
