import { Image } from 'models/Medium';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import getImageUrl from 'utils/getImageUrl';
import { isGif } from 'utils/imageUtils';
interface Size {
  width: number | string;
  height: number | string;
}
interface Props {
  image: Image;
  style?: CSSProperties;
  noFrame?: boolean;
}
const ImageHolder: React.FC<Props> = ({ image, style, noFrame = false }) => {
  const [containerSize, setContainerSize] = useState<Size>({
    width: '100%',
    height: 'auto'
  });

  useEffect(() => {
    calculateSize();
  }, []);

  const containers = useRef<HTMLDivElement>(null);
  const calculateSize = () => {
    if (!containers.current) return;
    const container = containers.current.parentNode as HTMLDivElement;
    const containerRect = container.getBoundingClientRect();

    setContainerSize({
      width: containerRect.width,
      height: 'auto'
    });
  };

  const { url, largeUrl } = image;
  const backgroundStyle = {
    width: !containerSize.width ? '100%' : containerSize.width,
    height: containerSize.height,
    backgroundColor: image.avg,
    zIndex: -10
  };

  if (isGif(image)) {
    return (
      <div style={backgroundStyle} ref={containers}>
        <img
          src={getImageUrl(url)}
          className="img_thumb"
          style={style}
          alt=""
        />
        {!noFrame && <span className="frame_g" />}
        <span className="txt_gif">GIF</span>
      </div>
    );
  }

  return (
    <div style={backgroundStyle} ref={containers}>
      <img
        src={getImageUrl(largeUrl || url)}
        className="img_thumb"
        style={style}
        alt=""
      />
      {!noFrame && <span className="frame_g" />}
    </div>
  );
};

export default ImageHolder;
