import React from 'react';

interface Props {
  viewBottomSheet: Function;
}

const BusinessBadge: React.FC<Props> = ({ viewBottomSheet }) => {
  return (
    <span className="ico_plus ico_official"
     onClick={e => viewBottomSheet(e)}
     style={{cursor: 'pointer'}}
    >
      공식채널
    </span>
  )
};

export default BusinessBadge;
