import { schema } from 'normalizr';

export const video = new schema.Entity('videos');
export const image = new schema.Entity('images');
export const link = new schema.Entity('links');

export const profile = new schema.Entity(
  'profiles',
  {
    profileImage: image,
    coverImage: image
  },
  { idAttribute: 'profileId' }
);

export const comment = new schema.Entity('comments');

export const coupon = new schema.Entity('coupons', {
  winImage: image,
  entryImage: image
});

export const card = new schema.Entity('cards', {
  medium: image
});

export const verticalCard = new schema.Entity('verticalCards', {
  medium: image
});

export const couponCard = new schema.Entity('couponCards', {
  coupon: coupon
});

export const medium = new schema.Union(
  {
    couponCard,
    verticalCard,
    card,
    image,
    link,
    video
  },
  'type'
);

export const post = new schema.Entity('posts', {
  author: profile,
  media: [medium],
  comments: { items: [comment] }
});

export const connection = new schema.Entity(
  'connections',
  {},
  { idAttribute: 'connectionId' }
);

export const hometab = new schema.Entity(
  'hometab',
  {},
  { idAttribute: () => 'recent' }
);

export const realtime = new schema.Entity(
  'realtime',
  {},
  { idAttribute: 'id' }
);
