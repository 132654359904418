import React from 'react';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import LinkImage from './LinkImage';
import { IMAGE_TYPE } from 'state/modules/home/constants';
import { Image, LinkScrap } from 'models/Medium';

interface Props {
  type: string;
  link: LinkScrap;
  tiaraClickHandler?: Function;
}
const LinkView: React.FC<Props> = ({ type, link, tiaraClickHandler }) => {
  let imageType = IMAGE_TYPE.none;
  if (!isEmpty(link.images)) {
    imageType =
      head(link.images)!.width > 350 && head(link.images)!.height > 140
        ? IMAGE_TYPE.big
        : IMAGE_TYPE.small;
  }

  return (
    <div
      className={classNames('card_default', 'card_scrab', {
        card_scrab2: imageType === IMAGE_TYPE.small,
        card_scrab3: type === 'feed' && imageType === IMAGE_TYPE.none
      })}
    >
      <a
        href={link.requestedUrl}
        className="link_card"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          if (tiaraClickHandler) tiaraClickHandler();
        }}
      >
        <LinkImage image={head(link.images) as Image} imageType={imageType} />
        <div className="info_cont">
          <strong className="tit_card">{link.title}</strong>
          {link.description && link.description !== '' ? (
            <p className="desc_card">{link.description}</p>
          ) : null}
          <span className="txt_url">{link.host}</span>
        </div>
      </a>
    </div>
  );
};

export default LinkView;
