import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { homeOperations } from 'state/modules/home';
import { LeverageTabModule, LeverageTabMap } from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import HomeTabHeader from './HomeTabHeader';
import { RootState } from 'state/modules';
import { Realtime } from 'models/Local';
import openWithApp from 'utils/openWithApp';
import { isEmpty } from 'lodash';
import StaticMap from './StaticMap';
interface Props {
  profile: Profile;
  module: LeverageTabModule;
  realtime: Realtime;
  fetchRealtime: Function;
}
const ModuleMap: React.FC<Props> = ({
  profile,
  module,
  fetchRealtime,
  realtime
}) => {
  const placeInfo: LeverageTabMap = module?.contents[0];
  const useragent = useSelector((state: RootState) => state.home.useragent);

  useEffect(() => {
    if (fetchRealtime && placeInfo?.localCid) {
      fetchRealtime(String(placeInfo?.localCid));
    }
  }, [fetchRealtime, placeInfo]);

  const isPC = useMemo(() => {
    const deviceOS = useragent?.result?.os?.name;
    return deviceOS !== 'Android' && deviceOS !== 'iOS';
  }, [useragent]);

  const getRealtimeLabel = () => {
    const isOpen = realtime?.open === 'Y';
    const isHoliday = realtime?.holiday === 'Y';

    let statusLabel = '';
    if (isOpen && !isHoliday) {
      statusLabel = '영업중';
    } else if (!isOpen && !isHoliday) {
      statusLabel = realtime?.secondsUtilOpen || 0 ? '영업전' : '영업종료';
    } else if (isHoliday) {
      statusLabel = '휴무';
    }

    let period = '';
    if (realtime?.currentPeriod?.matchedTime) {
      if (realtime?.currentPeriod?.matchedTime?.timeName === '영업시간') {
        period = `${realtime?.currentDayOfWeek}요일`;
      } else {
        period = realtime?.currentPeriod?.matchedTime?.timeName;
      }
      period = `${period} ${realtime?.currentPeriod?.matchedTime?.timeSE}`;
    }
    if (isEmpty(period)) {
      return statusLabel;
    }
    return `${statusLabel}, ${period}`;
  };

  const handleClickPlaceInfo = () => {
    if (!!placeInfo?.localCid) {
      window.open(`https://m.map.kakao.com/scheme/place?id=${placeInfo?.localCid}&infoLevel=2&referrer=TalkChannel&utm_source=T alkChannel`);
    }
  };

  const handleClickKakaoMap = () => {
    if (!!placeInfo?.localCid) {
      if (isPC) {
        window.open(`https://map.kakao.com/?itemId=${placeInfo?.localCid}`);
      } else {
        openWithApp(
          useragent?.result?.os?.name || '',
          'daummaps',
          `look?p=${placeInfo?.y},${placeInfo?.x}&referrer=channelWebHome`,
          'net.daum.android.map',
          'https://itunes.apple.com/kr/app/id304608425'
        );
      }
    }
  };

  const handleClickKakaoNavi = () => {
    if (!!placeInfo?.localCid) {
      if (isPC) {
        alert('PC에서는 사용하실 수 없습니다.\n모바일에서 확인해 주세요.');
      } else {
        const name = encodeURIComponent(placeInfo?.localName || '');
        openWithApp(
          useragent?.result?.os?.name || '',
          'kakaonavi',
          `navigate?coord_type=wgs84&name=${name}&y=${placeInfo?.y}&x=${placeInfo?.x}&key=194c329945a945bfbd19d0121f6b47bb`,
          'com.locnall.KimGiSa',
          'https://itunes.apple.com/kr/app/id417698849'
        );
      }
    }
  };

  const isSite = !!placeInfo?.localCid;
  return (
    <div className="wrap_home wrap_home_store">
      <HomeTabHeader header={module?.header} />
      <StaticMap show={isSite} x={placeInfo?.x} y={placeInfo?.y} />
      {isSite && (
        <>
          <dl className="list_store">
            <dt>
              <span className="ico_plus ico_point">위치</span>
            </dt>
            <dd>
              <span className="txt_info">{placeInfo?.localName || ''}</span>
              <span className="desc_info">{placeInfo?.address?.text}</span>
            </dd>
            {realtime && (
              <>
                <dt>
                  <span className="ico_plus ico_time">영업시간</span>
                </dt>
                <dd>
                  <span className="txt_info">{getRealtimeLabel()}</span>
                </dd>
              </>
            )}
            <dt>
              <span className="ico_plus ico_mystore">장소정보</span>
            </dt>
            <dd>
              <a
                href="#none"
                className="link_store"
                onClick={handleClickPlaceInfo}
              >
                <span className="txt_mystore">장소 정보 자세히 보기</span>
              </a>
            </dd>
          </dl>
          <div className="area_btn">
            <button
              type="button"
              className="btn_store "
              onClick={handleClickKakaoMap}
            >
              {/* 버튼 press 시 active 클래스 추가 */}
              <span className="ico_plus ico_map" />
              길찾기
            </button>
            <button
              type="button"
              className="btn_store "
              onClick={handleClickKakaoNavi}
            >
              {/* 버튼 press 시 active 클래스 추가 */}
              <span className="ico_plus ico_navi" />
              카카오네비
            </button>
          </div>
        </>
      )}
      {!isSite && (
        <dl className="list_store">
          <dt>
            <span className="ico_plus ico_point">위치</span>
          </dt>
          <dd>
            <span className="txt_info">{placeInfo?.address?.text || ''}</span>
          </dd>
        </dl>
      )}
    </div>
  );
};

function mapStatetoProps(state: RootState, props: any) {
  const { realtime } = state.home?.entities;
  return {
    realtime
  };
}

export default connect(mapStatetoProps, dispatch =>
  bindActionCreators(
    {
      fetchRealtime: homeOperations.fetchRealtime
    },
    dispatch
  )
)(ModuleMap);
