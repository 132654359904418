import moment from 'moment';

export function durationFormat(seconds: number) {
  const ts = moment.utc(moment.duration(seconds, 'seconds').asMilliseconds());
  if (seconds > 60 * 60) {
    return ts.format('HH:mm:ss');
  }
  return ts.format('mm:ss');
}

export function convertToTime(time: string) {
  if (typeof time !== 'string') {
    return '';
  }

  const timeArr = [time.slice(0, 2), time.slice(2, 4)];
  return `${timeArr[0]}:${timeArr[1]}`;
}
