import { Post } from 'models/Post';
import { Profile } from 'models/Profile';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/modules';
import OpenAppButton from 'views/components/OpenAppButton';

interface Props {
  profile?: Profile;
  post?: Post;
  postArchive?: boolean;
}
const Gnb: React.FC<Props> = ({ profile, post, postArchive }) => {
  const useragent = useSelector((state: RootState) => state.home.useragent);
  return (
    <div id="kakaoHead" role="banner">
      <h1 className="tit_logo">
        <span className="ico_plus ico_logo">카카오톡 채널</span>
      </h1>
      <div className="wrap_btn">
        {(post || profile) && (
          <OpenAppButton
            profile={profile}
            post={post}
            postArchive={postArchive}
            useragent={useragent}
          />
        )}
      </div>
    </div>
  );
};

export default Gnb;
