import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { RouteChildrenProps } from 'react-router';

import { profile as profileSchema, post as postSchema } from './schemas';
import { RootState } from '..';
import { Connection } from 'models/Connection';

const entitiesSelector = (state: RootState) => state.home.entities;

interface PostSelectParams {
  postId?: string;
}

const postSelector = (
  { home: { entities } }: RootState,
  { match }: RouteChildrenProps<PostSelectParams>
) => {
  const postId = match?.params.postId;
  if (!postId) return null;
  return entities.posts && entities.posts[postId];
};

const getPost = createSelector(
  entitiesSelector,
  postSelector,
  (entities, post) => {
    if (post) {
      return denormalize(post, postSchema, entities);
    }
    return null;
  }
);

interface ProfileSelectParams {
  encodedProfileId?: string;
}

const profileSelector = (
  { home: { entities } }: RootState,
  { match }: RouteChildrenProps<ProfileSelectParams>
) => {
  const encodedProfileId = match?.params.encodedProfileId;
  if (!encodedProfileId) return null;
  return (
    entities.profiles && encodedProfileId && entities.profiles[encodedProfileId]
  );
};

interface ConnectionSelectParams {
  encodedProfileId?: string;
}

const connectionSelector = (
  { home: { entities } }: RootState,
  { match }: RouteChildrenProps<ConnectionSelectParams>
) => {
  const encodedProfileId = match?.params.encodedProfileId;
  if (!encodedProfileId) return null;
  const profile =
    entities &&
    entities.profiles &&
    encodedProfileId &&
    entities.profiles[encodedProfileId];
  if (!profile || !profile.id) return [];
  const filtered =
    entities.connections &&
    Object.entries<Connection>(entities.connections)
      .map(([key, connection]) => connection)
      .filter(connection => connection.profileId === profile.id);
  return filtered;
};

const hometabSelector = (
  { home: { entities } }: RootState,
  { match }: RouteChildrenProps<ConnectionSelectParams>
) => {
  const encodedProfileId = match?.params.encodedProfileId;
  if (!encodedProfileId) return null;
  const profile =
    entities &&
    entities.profiles &&
    encodedProfileId &&
    entities.profiles[encodedProfileId];
  if (!profile || !profile.id) return [];
  return entities.hometab?.recent ?? {};
};

const getProfile = createSelector(
  entitiesSelector,
  profileSelector,
  (entities, profile) => {
    return denormalize(profile, profileSchema, entities);
  }
);

const getProfilePosts = createSelector(
  entitiesSelector,
  profileSelector,
  (entities, profile) => {
    if (profile && profile.posts) {
      return profile.posts.items.map((postId: number) =>
        denormalize(entities.posts[`${postId}`], postSchema, entities)
      );
    }
    return [];
  }
);

const getProfileConnections = createSelector(
  connectionSelector,
  connections => connections
);

const getProfileHomeTab = createSelector(hometabSelector, homeTab => homeTab);

export default {
  getPost,
  getProfile,
  getProfilePosts,
  getProfileHomeTab,
  getProfileConnections
};
