import React from 'react';
import BaseModal from 'views/components/Modal/BaseModal';
import { Profile } from 'models/Profile';

interface Props {
  profile: Profile;
  blockFriendlink: string;
  onHideModal: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}
const BlockFriendModal: React.FC<Props> = ({
  profile,
  blockFriendlink,
  onHideModal
}) => {
  const defaultStyle: ReactModal.Styles = {
    content: {
      position: 'absolute',
      border: 0,
      top: '50%',
      left: '50%',
      marginTop: -120
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9998,
      backgroundColor: 'rgba(0,0,0,0.75)',
      width: '100%',
      height: '100%'
    }
  };
  return (
    <BaseModal
      contentLabel="친구차단"
      onRequestClose={onHideModal}
      style={defaultStyle}
    >
      {{
        renderTitle: () => (
          <div>
            <span>채널 차단</span>
          </div>
        ),
        renderContent: () => (
          <div>
            <p className="txt_layer">채널을 차단하시겠습니까?</p>
            <div className="app_btn type_btns">
              <button type="button" className="btn_g" onClick={onHideModal}>
                <span className="inner_txt">취소</span>
              </button>
              <button
                type="button"
                className="btn_g"
                onClick={() => {
                  window.location.href = blockFriendlink;
                }}
              >
                <span className="inner_txt">차단</span>
              </button>
            </div>
          </div>
        )
      }}
    </BaseModal>
  );
};

export default BlockFriendModal;
