import { Call2Action } from './Profile';

export enum LeverageTabModuleType {
  banner = 'banner',
  commerce = 'commerce',
  bottom_button = 'bottom_button',
  notice = 'notice',
  coupon = 'coupon',
  simple_post = 'simple_post',
  chat = 'chat',
  keyword_chat = 'keyword_chat',
  map = 'map',
  text_info = 'text_info',
  business = 'business'
}

interface LeverageTabContent {
  sort: number;
}

export interface LeverageTab {
  items: Array<LeverageTabModule>;
  floatingItems: Array<LeverageTabModule>;
  kakaoBusinessInfo: any; // TODO
}

export interface LeverageTabModule {
  type: LeverageTabModuleType;
  contents: Array<any>; // TODO
  displayCols: number;
  header: LeverageTabHeader;
  buttons: Array<LeverageTabButton>;
}

export interface LeverageTabHeader {
  title: LeverageTabText;
  button: LeverageTabButton;
  image: LeverageTabImage;
  link: Call2Action;
}

export interface LeverageTabItems extends LeverageTabContent {
  items: Array<any>;
}

export interface LeverageTabButton extends LeverageTabContent {
  title: LeverageTabText;
  link: LeverageTabLink;
  bgColor: string;
  opacity: number; //0~1.0
}

export interface LeverageTabImage {
  imageUrl: string;
  link: LeverageTabLink;
  width: number;
  height: number;
}

export interface LeverageTabLink {
  url: string;
  andUrl: string;
  iosUrl: string;
  image: LeverageTabImage;
  host: string;
  type: string;
}

export interface LeverageTabText {
  text: string;
  link?: LeverageTabLink;
  isStrike?: boolean;
  isBold?: boolean;
  fontSize?: number;
  fontColor?: string;
  bgColor?: string;
  isCharged?: boolean;
}

export interface LeverageTabNotice extends LeverageTabContent {
  postId: number;
  title: LeverageTabText;
  description: LeverageTabText;
  link: LeverageTabLink;
  media: LeverageTabMedia;
}

export interface LeverageTabCoupon extends LeverageTabContent {
  id: number;
  status: 'draft' | 'end' | 'upload' | 'fail' | 'done' | 'complete' | 'expired';
  entryStartAt: number;
  entryEndAt: number;
  usedStartAt: number;
  usedEndAt: number;
  isUsed?: boolean;
  isDownloaded?: boolean;
  title: LeverageTabText;
  link: LeverageTabLink;
  image: LeverageTabImage;
}

export interface LeverageTabBadge {
  title: string;
  subTitle: string;
  bgColor: string;
}

export interface LeverageTabCurrency {
  unit: string; // 원
  position: number;
}

export interface LeverageTabPrice {
  amount: number;
  amountPrefix: string;
  amountSuffix: string;
  discountAmount: number;
  discountRate: number;
}

export interface LeverageTabMedia {
  type: 'image' | 'video' | 'link' | 'card';
  image: LeverageTabImage;
  link: LeverageTabLink;
  video: LeverageTabVideo;
  count: number;
}

export interface LeverageTabVideo {
  duration: number;
  thumbnail: LeverageTabImage;
  playUrl: string;
  isLive: boolean;
}

export interface LeverageTabCommerce extends LeverageTabContent {
  title: LeverageTabText;
  link: LeverageTabLink;
  image: LeverageTabImage;
  badge: LeverageTabBadge;
  currency: LeverageTabCurrency;
  price: LeverageTabPrice;
  discountedPrice: LeverageTabPrice;
  buttons: Array<LeverageTabButton>;
}

export interface LeverageTabSimplePost extends LeverageTabContent {
  id: number;
  uuid: string;
  title: LeverageTabText;
  link: LeverageTabLink;
  media: LeverageTabMedia;
}

export interface LeverageTabChat extends LeverageTabContent {
  consultFlag: boolean;
  isChatBot: boolean;
  bizchatFlag: boolean;
  weekFlags: string;
  startAt: string;
  endAt: string;
  botStartMessage: string;
  keywords: Array<string>;
  timeGroups: Array<ChatTimeGroup>;
}

export interface LeverageTabChatKeyword extends LeverageTabContent {
  keyword: string;
}

export interface LeverageTabMap extends LeverageTabContent {
  address: LeverageTabText;
  localCid: number;
  localName: string;
  x: number;
  y: number;
}

export interface LeverageTabInfo extends LeverageTabContent {
  title: LeverageTabText;
  description: LeverageTabText;
  link: LeverageTabLink;
}

export interface ChatTimeGroup {
  daysAlias: string;
  days: string;
  startAt: string;
  endAt: string;
}
