export const PROFILE_IMAGE = 'PROFILE_IMAGE';

// Modal Type
export const SHARE = 'MODAL_TYPE_SHARE';
export const COMMENT = 'MODAL_TYPE_COMMENT';
export const LIKE = 'MODAL_TYPE_LIKE';
export const AGE_AUTH = 'MODAL_TYPE_AGE_AUTH';
export const ADD_FRIEND = 'MODAL_TYPE_ADD_FRIEND';
export const BLOCK_FRIEND = 'MODAL_TYPE_BLOCK_FRIEND';
export const KREW_PRIVATE_PROFILE = 'MODAL_TYPE_KREW_PRIVATE_PROFILE';

export const PUBLIC_INSTITUTION = 'PUBLIC_INSTITUTION';

export const BUSINESS = 'BUSINESS';
export const ACTION_TYPE = {
  TALKSTORE: 'talkstore',
  STORE: 'store',
  TEL: 'tel'
};
export const ACTION_BUTTON = {
  participate: { class: 'party', label: '참여하기' },
  tel: { class: 'call', label: '전화하기' },
  coupon: { class: 'coupon', label: '쿠폰받기' },
  video: { class: 'video', label: '동영상보기' },
  more: { class: 'examine', label: '더 알아보기' },
  donate: { class: 'gift', label: '기부하기' },
  inquiry: { class: 'detail', label: '문의하기' },
  web: { class: 'web', label: '웹사이트' },
  game: { class: 'game', label: '게임하기' },
  openchat: { class: 'chat', label: '오픈채팅' },
  app: { class: 'app', label: '앱 사용하기' },
  buy: { class: 'buy', label: '구매하기' },
  reserve: { class: 'reserve', label: '예약하기' },
  subscribe: { class: 'join', label: '가입하기' },
  order: { class: 'order', label: '주문하기' },
  talkorder: { class: 'talkorder', label: '톡 주문하기' },
  music: { class: 'music', label: '음악듣기' },
  store: { class: 'store', label: '스토어' },
  talkstore: { class: 'talkstore', label: '톡 스토어' },
  cafe: { class: 'cafe', label: '공식카페' },
  hospital: { class: 'hospital', label: '병원가기' },
  membership: { class: 'membership', label: '멤버십' }
};
export const IMAGE_TYPE = {
  big: 'big',
  small: 'small',
  none: 'none'
};
export const direction = {
  forward: 'forward',
  backward: 'backward'
};

export const iosInstallUrl = 'https://itunes.apple.com/kr/app/id362057947';
export const androidInstallUrl =
  'https://play.google.com/store/apps/details?id=com.kakao.talk&hl=ko';

export const ADULT_AUTH_VALID_TYPE = {
  UNAUTHORIZED: 'unauthorized',
  UNAUTHORIZED_AGE: 'unauthorized_age',
  VERIFIED: 'verified',
  NOT_FOUND: 'not_found',
  LOWER_AGE_LIMIT: 'lower_age_limit'
};
