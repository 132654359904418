import React from 'react';
interface Accessbility {
  content: string;
}
interface Props {
  accessbility: Accessbility;
  fillPage?: boolean;
}
const ContentWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  accessbility = {},
  fillPage
}) => (
  <>
    <div
      id="kakaoContent"
      className="cont_plus"
      role="main"
      style={{ maxWidth: fillPage ? 'unset' : '' }}
    >
      <div id="mArticle">
        {accessbility.content && (
          <h2 id="kakaoBody" className="screen_out">
            {accessbility.content}
          </h2>
        )}
        {children}
      </div>
    </div>
  </>
);

export default ContentWrapper;
