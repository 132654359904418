import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'state/modules';
import HomeLayout from 'views/layouts/Home';
// import ErrorPage from 'views/pages/ErrorPage';

interface Props {
  router: any;
  error?: any;
}

const App: React.FC<Props> = ({ router, error }) => {
  if (error) {
    console.error(error);
  }
  return <HomeLayout>{router}</HomeLayout>;
}

export default connect((state: RootState) => {
  const { error } = state.home.error || {};
  return {
    error
  };
})(App);
