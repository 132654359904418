import axios from 'axios';
import { camelizeKeys } from 'humps';
import { Pageable } from 'models/Common';
import { Connection } from 'models/Connection';
import { LeverageTab } from 'models/LeverageTemplate';
import { Realtime } from 'models/Local';
import { Post } from 'models/Post';
import { Profile } from 'models/Profile';
import { normalize } from 'normalizr';
import queryString from 'query-string';
import {
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileFailure,
  fetchPostRequest,
  fetchPostSuccess,
  fetchPostFailure,
  fetchPostsRequest,
  fetchPostsSuccess,
  fetchPostsFailure,
  fetchCommentsRequest,
  fetchCommentsSuccess,
  fetchCommentsFailure,
  fetchRealtimeRequest,
  fetchRealtimeSuccess,
  fetchRealtimeFailure,
  showModal
} from 'state/modules/home/actions';
import {
  profile as profileSchema,
  post as postSchema,
  comment as commentSchema,
  connection as connectionSchema,
  hometab as hometabSchema,
  realtime as realtimeSchema
} from 'state/modules/home/schemas';
import { KREW_PRIVATE_PROFILE } from './constants';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true
});

interface WebProfileResponse {
  profile: Profile;
  posts: Pageable<Post>;
  connections: Connection[];
  hometab: LeverageTab;
}

const fetchProfile = (profileId: string) => (dispatch: any) => {
  dispatch(fetchProfileRequest());
  return client
    .get(`/web/profiles/${profileId}`)
    .then(result => {
      const camelized = (camelizeKeys(
        result.data
      ) as object) as WebProfileResponse;
      camelized.posts.items?.forEach(post => {
        post.author = camelized.profile;
      });
      const normalized: any = normalize(camelized, {
        connections: [connectionSchema],
        profile: profileSchema,
        posts: { items: [postSchema] },
        hometab: hometabSchema
      });
      normalized.entities.profiles[profileId].posts = normalized.result.posts;
      dispatch(fetchProfileSuccess(normalized));
    })
    .catch(error => {
      if (
        error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data.code === -201000
      ) {
        dispatch(showModal(KREW_PRIVATE_PROFILE, { profileId }));
      }

      dispatch(fetchProfileFailure(error));
    });
};

const fetchPost = (profileId: string, postId: number) => (dispatch: any) => {
  dispatch(fetchPostRequest(profileId, postId));
  return client
    .get(`/web/profiles/${profileId}/posts/${postId}`)
    .then(result => {
      return dispatch(
        fetchPostSuccess(
          profileId,
          postId,
          normalize(camelizeKeys(result.data), postSchema)
        )
      );
    })
    .catch(error => {
      if (
        error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data.code === -201000
      ) {
        dispatch(showModal(KREW_PRIVATE_PROFILE, { profileId, postId }));
      } else if (error?.response?.data?.code === -110000) {
        window.location.href = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=${window.location.href}`;
      }

      dispatch(fetchPostFailure(profileId, postId, error));
    });
};

const fetchPosts = (profileId: string, opts: any = {}) => (dispatch: any) => {
  dispatch(fetchPostsRequest(profileId, opts));
  return client
    .get(`/web/profiles/${profileId}/posts?${queryString.stringify(opts)}`)
    .then(result => {
      const camelized = (camelizeKeys(result.data) as object) as Pageable<Post>;
      camelized.items?.forEach((post: any) => {
        post.author = profileId;
      });
      const normalizedPosts = normalize(camelized.items, [postSchema]);
      return dispatch(
        fetchPostsSuccess(profileId, normalizedPosts, camelized.hasNext)
      );
    })
    .catch(error => {
      dispatch(fetchPostsFailure(profileId, error));
    });
};

const fetchComments = (profileId: string, postId: number, opts: any = {}) => (
  dispatch: any
) => {
  dispatch(fetchCommentsRequest(profileId, postId));
  return client
    .get(
      `/web/profiles/${profileId}/posts/${postId}/comments?${queryString.stringify(
        opts
      )}`
    )
    .then(result => {
      const comments = (camelizeKeys(result.data) as object) as Pageable<
        Comment
      >;
      return dispatch(
        fetchCommentsSuccess(
          profileId,
          postId,
          opts.direction,
          comments.hasNext,
          normalize(comments.items, [commentSchema])
        )
      );
    })
    .catch(error => dispatch(fetchCommentsFailure(profileId, postId, error)));
};

const fetchRealtime = (cid: string) => (dispatch: any) => {
  dispatch(fetchRealtimeRequest(cid));
  return client
    .get(`/web/local/${cid}/realtime`)
    .then(result => {
      if (typeof result.data != 'object') {
        return dispatch(fetchRealtimeSuccess(cid, { result: null }));
      }
      const camelized = (camelizeKeys(result.data) as object) as Realtime;
      const normalized: any = normalize(camelized, {
        realtime: realtimeSchema
      });
      return dispatch(fetchRealtimeSuccess(cid, normalized));
    })
    .catch(error => dispatch(fetchRealtimeFailure(cid, error)));
};

export default {
  fetchProfile,
  fetchPost,
  fetchPosts,
  fetchComments,
  fetchRealtime
};
