import React from 'react';
import { LeverageTabModule, LeverageTabInfo } from 'models/LeverageTemplate';
import { Profile } from 'models/Profile';
import HomeTabHeader from './HomeTabHeader';
import { sortBy } from 'lodash';
import HomeTabInfoItem from './HomeTabInfoItem';
import HomeTabCall2ActionButton from './HomeTabCall2ActionButton';

interface Props {
  profile: Profile;
  module: LeverageTabModule;
}
const ModuleProfileInfo: React.FC<Props> = ({ profile, module }) => {
  const infos: Array<LeverageTabInfo> = sortBy(module?.contents, 'sort');

  return (
    <div className="wrap_home wrap_home_info">
      <HomeTabHeader header={module?.header}>
        {!!profile.call2action ? (
          <HomeTabCall2ActionButton link={module?.header?.link} />
        ) : (
          ''
        )}
      </HomeTabHeader>
      <dl className="list_home">
        {infos?.map((info, idx) => (
          <HomeTabInfoItem key={idx} info={info} />
        ))}
      </dl>
    </div>
  );
};

export default ModuleProfileInfo;
