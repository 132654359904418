import get from 'lodash/get';

export function getOsName(device: any) {
  if (isIOS(device)) {
    return 'iOS';
  }

  return get(device, 'os.name', null);
}

function isIOS(device: any) {
  const deviceOS = get(device, 'os.name', null);
  return deviceOS === 'iOS' || (deviceOS === 'Mac OS' && isMobile(device)); // ** ios >= 13부터 iPad의 ua는 os = Mac OS & type = mobile로 내려줌 (https://developer.apple.com/forums/thread/119186)
}

export function isMobile(device: any) {
  if (get(device, 'device.type') === 'mobile') {
    return true;
  } else if (get(device, 'device.model') === 'iPad') {
    return true;
  } else if (get(device, 'device.type') === 'tablet') {
    return true;
  }
  return false;
}
