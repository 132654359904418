import React from 'react';
import EmptyPost from 'views/components/EmptyPost';
import PostCard from 'views/components/Post';
import FeedFooter from 'views/components/FeedFooter';
import { Profile } from 'models/Profile';
import { Post } from 'models/Post';

interface Props {
  profile: Profile;
  posts: Post[];
  hasNext: boolean;
  onShowModal: Function;
  layerCode: Function;
}

const Feed: React.FC<Props> = ({
  profile,
  posts,
  hasNext,
  onShowModal,
  layerCode
}) => {
  if (posts.length === 0) {
    return <EmptyPost profile={profile} />;
  }

  return (
    <div>
      {posts.map((post, sortIdx) => (
        <PostCard
          key={post.id}
          type="feed"
          post={post}
          onShowModal={onShowModal}
          layerCode={layerCode}
          sortIdx={sortIdx}
        />
      ))}
      <FeedFooter hasNext={hasNext} />
    </div>
  );
};

export default Feed;
