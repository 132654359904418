import React from 'react';
import nl2br from 'utils/nl2br';

interface Props {
  title: string;
  description: string;
}
const ErrorContent: React.FC<Props> = ({ title, description }) => (
  <div className="info_err">
    <span className="ico_plus"></span>
    <strong className="tit_err">{title}</strong>
    <p className="desc_err">
      {nl2br(description)}
    </p>
  </div>
);

export default ErrorContent;
