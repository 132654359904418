import React, { useCallback, useEffect, useMemo } from 'react';
import Gnb from 'views/components/Gnb';
import { getOsName } from 'utils/deviceUtils';
import { Profile } from 'models/Profile';
import { androidInstallUrl, iosInstallUrl } from 'state/modules/home/constants';
import openWithApp from 'utils/openWithApp';
import { RootState } from 'state/modules';
import { useSelector } from 'react-redux';

interface Props {
  profile: Profile;
  url: string;
}

const MobileBridgePage: React.FC<Props> = ({ profile, url }) => {
  const useragent = useSelector(
    (state: RootState) => {
      return state.home.useragent.result;
    },
    () => false
  );

  const deviceOS = useMemo(() => {
    return getOsName(useragent);
  }, [useragent]);

  const onTalkExec = useCallback(() => {
    const profilePath = `plusfriend/home/${profile.id}?url=` + url;
    const installUrl =
      deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl;
    openWithApp(
      deviceOS,
      deviceOS === 'iOS' 
        ? process.env.REACT_APP_PF_CUSTOM_SCHEME || 'kakaoplus'
        : 'kakaoplus',
      profilePath,
      'com.kakao.talk',
      installUrl
    );
  }, [deviceOS, profile.id, url]);

  useEffect(() => {
    onTalkExec();
  }, [onTalkExec, profile.id, url, useragent]);

  return (
    <>
      <Gnb />
      <div id="kakaoContent" className="cont_bridge" role="main">
        <div className="inner_bridge">
          <span className="ico_bridge ico_type3">채팅 시작</span>
          <strong className="tit_bridge">
            카카오톡에서 확인 가능한 링크입니다.
          </strong>
          <p className="desc_bridge">
            카카오톡을 설치하거나,
            <br /> 최신 버전으로 업데이트 하신 후 링크를 확인 해주세요.
          </p>
          <a
            href={deviceOS === 'Android' ? androidInstallUrl : iosInstallUrl}
            className="link_bridge link_type1"
          >
            카카오톡 최신버전 설치하기
          </a>
          <a
            href="#none"
            className="link_bridge link_type2"
            onClick={() => onTalkExec()}
          >
            카카오톡에서 시작하기
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileBridgePage;
