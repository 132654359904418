import React from 'react';
import Gnb from 'views/components/Gnb';
import ContentWrapper from 'views/components/ContentWrapper';
import ErrorContent from 'views/components/ErrorContent';

const SDKErrorPage: React.FC = () => (
  <>
    <Gnb />
    <ContentWrapper accessbility={{ content: '에러 본문영역' }}>
      <ErrorContent
        title="잘못된 접근입니다."
        description={
          '인증에 실패하여 페이지에 접근할 수 없습니다.\n해당 기능을 제공한 서비스 관리자에게 문의해주세요.'
        }
      />
    </ContentWrapper>
  </>
);

export default SDKErrorPage;
