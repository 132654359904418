import React, { useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import numberWithCommas from 'utils/numberFormat';
import ProfileImage from 'views/components/ProfileImage';
import { Profile } from 'models/Profile';
import { Post } from 'models/Post';
import {
  ADD_FRIEND,
  BLOCK_FRIEND
} from '../../../state/modules/home/constants';
import { toast } from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';

interface Props {
  profile: Profile;
  post: Post;
  onShowModal: Function;
  layerCode: Function;
}

const ProfileBanner: React.FC<Props> = ({
  profile,
  post,
  onShowModal,
  layerCode
}) => {
  const { profileId, profileImage, name, statusMessage } = profile;
  const hasProfileImage = !isEmpty(profileImage);
  const hasStatusMessage =
    statusMessage !== 'undefined' && statusMessage !== '';
  const friendCount = get(profile, 'friendCount', 0);
  const [showAdditionalBtn, setShowAdditionalBtn] = useState(false);
  const link = `/${profileId}/friend_direct?post_id=${post.id}`;
  const currentUrl = window.location.href;
  const blockFriendlink = `/${profile?.profileId}/friend_block?returl=${currentUrl}%3FcompletedLayer=blockfriend`;
  const spamReportUrl =
    currentUrl.substr(0, currentUrl.lastIndexOf('/')) + '/spam_report';
  const openSpamReportLink = () => {
    const loginRedirectUrl = spamReportUrl;
    const logoutRedirectUrl = `${process.env.REACT_APP_KAKAO_ACCOUNT_URL}/login?continue=`;
    setShowAdditionalBtn(!showAdditionalBtn);
    window.open(
      `${process.env.REACT_APP_API_BASE_URL}/web/profiles/${profile?.profileId}/logincheck?logoutRedirectUrl=${logoutRedirectUrl}&loginRedirectUrl=${loginRedirectUrl}`,
      '_blank'
    );
  };

  return (
    <div className="box_profile">
      <div className="wrap_thumb">
        <a href={`/${profileId}`}>
          {hasProfileImage && <ProfileImage image={profileImage} />}
        </a>
      </div>
      <div className="post_info">
        <div className="inner_info">
          <strong className="txt_name">
            <a href={`/${profileId}`}>{name}</a>
          </strong>
          {hasStatusMessage && (
            <span className="txt_info">{statusMessage}</span>
          )}
          {friendCount > 0 && (
            <span className="txt_friend">
              친구{' '}
              <span className="emph_g">{numberWithCommas(friendCount)}</span>명
            </span>
          )}
        </div>
      </div>
      <div className="post_util">
        <div className="area_util">
          {post.author.isFriend && (
            <button
              type="button"
              className="btn_util"
              onClick={() =>
                onShowModal(BLOCK_FRIEND, { profile, blockFriendlink })
              }
            >
              <span className="ico_plus ico_add_after">채널차단</span>
            </button>
          )}
          {!post.author.isFriend && (
            <button
              type="button"
              className="btn_util"
              onClick={() => onShowModal(ADD_FRIEND, { profile, link })}
            >
              <span className="ico_plus ico_add_before">채널추가</span>
            </button>
          )}
        </div>
        <div className="area_nav">
          <button
            type="button"
            className="btn_nav"
            onClick={() => setShowAdditionalBtn(!showAdditionalBtn)}
          >
            <span className="ico_plus ico_nav_more">더보기</span>
          </button>
          <div
            className="layer_opt"
            style={{ display: !!showAdditionalBtn ? '' : 'none' }}
          >
            <ul className="list_opt">
              <li>
                <button type="button" className="btn_opt">
                  <span
                    className="txt_opt"
                    onClick={() => openSpamReportLink()}
                  >
                    신고하기
                  </span>
                </button>
              </li>
              <li>
                <CopyToClipboard
                  text={`${window.location.origin}/${profileId}`}
                  onCopy={() => {
                    toast('URL을 복사하였습니다.');
                    setShowAdditionalBtn(!showAdditionalBtn);
                  }}
                >
                  <button type="button" className="btn_opt">
                    URL 복사하기
                  </button>
                </CopyToClipboard>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBanner;
