import React from 'react';
import { resolveValue, Toaster } from 'react-hot-toast';

const BottomToaster = () => {
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      gutter={8}
      containerClassName="toast_plus"
      containerStyle={{
        left: 0,
        bottom: 45
      }}
      toastOptions={{
        duration: 4000
      }}
    >
      {t => (
        <div className="toast_layer">
          <div className="layer_body">{resolveValue(t.message, t)}</div>
        </div>
      )}
    </Toaster>
  );
};

export { BottomToaster };
