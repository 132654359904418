import { Video } from 'models/Medium';
import React from 'react';
import VideoPlayer from 'views/components/Post/media/VideoPlayer';

interface Props {
  video: Video;
}
const VideoView: React.FC<Props> = ({ video }) => {
  return <VideoPlayer video={video} />;
};

export default VideoView;
